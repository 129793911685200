import React, { useContext, useState } from "react";
import { Context } from "../components/Context";
import { Alert, Backdrop, Button, CircularProgress, Collapse, Grid, TextField, Typography } from "@mui/material";
import LoginIcon from '@mui/icons-material/Login';
import { Link } from "react-router-dom";

const Login : React.FC = () : React.ReactElement => {
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const {loginUser, userGetComplete, setUserGetComplete} = useContext(Context);
    const [showBackdrop, setShowBackdrop] = useState<boolean>(false);


    const tryLogin = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setUserGetComplete(undefined);
        setShowBackdrop(true);
        loginUser(username.toLowerCase(), password);
    }

    return(
        <div className="LoginPage">
            <Grid container spacing={1} sx={{width: "100vw", overflow:"scroll", height: "100vh", scrollbarWidth:"none", margin:"-2px"}}>
                <Grid item xs={12} md={6} sx={{backgroundColor: "#F3F3F3", height: "100vh", display:"flex", alignItems:"center", justifyContent:"center"}}>
                    <div style={{width :"50%"}}>
                        <div style={{marginTop:"20px", marginBottom:"20px"}}>
                            <img src="CX-Tailor-Logo-Slogan.png" height={100} alt="CX Tailor logo"/>
                        </div>
                        <Typography variant="h5">Sign in:</Typography>
                        <form onSubmit={tryLogin}>
                            <TextField id="email" fullWidth label="Email" variant="outlined"  type="email" className="loginField" margin="normal" required 
                            onChange={(e)=>{setUsername(e.target.value)}}/><br/>
                            <TextField id="password" fullWidth label="Password" variant="outlined" type="password" className="loginField" margin="normal" required 
                            onChange={(e)=>{setPassword(e.target.value)}}/><br/>
                            <Button className="btnMainClr" variant="contained" type="submit" startIcon={<LoginIcon />}
                            sx={{width:"100%", backgroundColor: " #6156A5", padding: "10px"}}
                            >Sign in</Button>
                        </form>
                        <Backdrop open={showBackdrop && userGetComplete === undefined}>
                            <CircularProgress color="secondary"/>
                        </Backdrop>
                        <Collapse in={userGetComplete === false} sx={{marginTop: "20px"}}>
                            <Alert severity="warning" variant="filled" onClose={()=>{setUserGetComplete(undefined); setShowBackdrop(false);}}>Wrong email or password</Alert>
                        </Collapse>
                        <Typography>Don't have an account? <Link to="/register">Create a free account here.</Link></Typography>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <div style={{color:"white", textAlign: "center", width:"80%"}}>
                        <Typography variant="h4">Start optimising your website</Typography><br/>
                        <Typography>
                            Seamlessly capture leads with interactive video/questionnaire funnels. Elevate your
                            customer journey and maximize your conversions. Monitor and optimize the performance of your lead magnets through our user friendly dashboards
                        </Typography>
                        <img src="CX-Tailor-mockup.jpg" alt="CX Tailor mockup" height={window.innerHeight * 0.50}/>
                    </div>
                </Grid>

            </Grid>
        </div>
    );
};

export default Login;