import { Button, Container, InputLabel, TextField, Typography } from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import { Context } from "./Context";

export const DisplayVideo = (props: any) : React.ReactElement => {
    const {steps} = useContext(Context);
    const [preview, setPreview] = useState({video : false});
    const videoRef = useRef<HTMLVideoElement>(null);
    const [timeLeftMessage, setTimeLeftMessage] = useState<boolean>(false);
    const [timeLeft, setTimeLeft] = useState<number>(0);

    const DisplayLeftTime = () =>{
        if(videoRef.current?.currentTime){
            if(videoRef.current.duration){
                if(steps[props.step].video.timeLeftPersentage){
                    if((videoRef.current.currentTime / videoRef.current.duration) * 100 > steps[props.step].video.timeLeftPersentage && timeLeftMessage === false){
                        setTimeLeftMessage(true);
                    }
                    if((videoRef.current.currentTime / videoRef.current.duration) * 100 < steps[props.step].video.timeLeftPersentage && timeLeftMessage === true){
                        setTimeLeftMessage(false);
                    }
                    setTimeLeft(Number((videoRef.current.duration - videoRef.current.currentTime).toFixed(0)));
                }
            }
        }
    }
    
    return(
        <div style={{width : "90%", padding : "10px"}}>
            {
                (steps[props.step].video.title.length > 0 && steps[props.step].video.description.length > 0 && steps[props.step].video.ctaText.length > 0 && preview.video === false)
                ?<>
                    {
                        (steps[props.step].video.textScreenStyle)
                        ?<div style={{
                            textAlign : steps[props.step].video.textScreenStyle.textAlign, 
                            padding : `${steps[props.step].video.textScreenStyle.padding}px`, 
                            backgroundColor : steps[props.step].video.textScreenStyle.backgroundColor,
                            height : "100%",
                            width : "100%"
                        }}>
                            <h2 style={{
                                fontSize : `${steps[props.step].video.h1Style.fontSize}px`, 
                                color : steps[props.step].video.h1Style.color
                            }}>{steps[props.step].video.title}</h2>
                            <p style={{
                                fontSize : `${steps[props.step].video.pStyle.fontSize}px`,
                                color : steps[props.step].video.pStyle.color
                            }}>{steps[props.step].video.description}</p>
                            <button onClick={()=>{setPreview({...preview, video : true});}} style={{
                                fontSize : `${steps[props.step].video.ctaStyle.fontSize}px`,
                                color : steps[props.step].video.ctaStyle.color,
                                backgroundColor : steps[props.step].video.ctaStyle.backgroundColor,
                                padding : `${steps[props.step].video.ctaStyle.padding}px`,
                                borderRadius : `${steps[props.step].video.ctaStyle.borderRadius}px`,
                                border : "none"

                            }}>{steps[props.step].video.ctaText}</button>
                         </div>
                        : <Container>
                        <Typography variant="h6">{steps[props.step].video.title}</Typography><br/>
                        <Typography>{steps[props.step].video.description}</Typography><br/>
                        <Button variant="contained" onClick={()=>{setPreview({...preview, video: true})}}>{steps[props.step].video.ctaText}</Button>
                    </Container>
                    }
                </>
                : (steps[props.step].video.url.length > 0 && steps[props.step].video.videoStyle)
                    ?<>
                        <video ref={videoRef} poster={steps[props.step].video.poster || ""} onTimeUpdate={()=>{DisplayLeftTime();}}
                        src={steps[props.step].video.url} className="previewVideo" width={`${steps[props.step].video.videoStyle.width}%`} controls
                        style={{borderRadius : `${steps[props.step].video.videoStyle.borderRadius}px`}}
                        onEnded={()=>{props.setStep(props.step + 1); setPreview({...preview, video : false}); setTimeLeftMessage(false);}}></video>
                        {
                                            (timeLeftMessage)
                                            ?<Typography style={{textAlign : "right"}}>{steps[props.step].video.timeLeftText}: {`${timeLeft}s`} </Typography>
                                            :null
                                        }
                    </>
                    :<>
                        <video ref={videoRef} poster={steps[props.step].video.poster || ""} onTimeUpdate={()=>{DisplayLeftTime();}}
                        src={steps[props.step].video.url} className="previewVideo" width={`100%`} controls
                        style={{borderRadius : `10px`}}
                        onEnded={()=>{props.setStep(props.step + 1); setPreview({...preview, video : false}); setTimeLeftMessage(false);}}></video>
                        {
                                            (timeLeftMessage)
                                            ?<Typography style={{textAlign : "right"}}>{steps[props.step].video.timeLeftText}: {`${timeLeft}s`} </Typography>
                                            :null
                                        }
                     </>
            }
        </div>
    );
} 

export const DisplayForm = (props : any) : React.ReactElement => {
    const {steps} = useContext(Context);

    return(
        <div style={{ width : "95%", height : "100%"}}>
            {
                (steps[props.step].form.firstNameLabel.length > 0 && steps[props.step].form.lastNameLabel.length > 0)
                ?<>
                    {
                        (steps[props.step].form.textScreenStyle)
                        ?<div style={{
                            textAlign : steps[props.step].form.textScreenStyle.textAlign, 
                            padding : `${steps[props.step].form.textScreenStyle.padding}px`, 
                            backgroundColor : steps[props.step].form.textScreenStyle.backgroundColor,
                            height : "100%",
                            width : "100%",
                            fontSize : `${steps[props.step].form.labelStyle.fontSize}px`,
                            color : steps[props.step].form.labelStyle.color
                        }}>
                            <h2 style={{
                                fontSize : `${steps[props.step].form.h1Style.fontSize}px`, 
                                color : steps[props.step].form.h1Style.color
                            }}>{steps[props.step].form.header}</h2>
                            <p style={{
                                fontSize : `${steps[props.step].form.pStyle.fontSize}px`,
                                color : steps[props.step].form.pStyle.color
                            }}>{steps[props.step].form.description}</p>
                            <label>{steps[props.step].form.firstNameLabel}</label><br/>
                            <input placeholder={steps[props.step].form.firstNameLabel} style={{
                                width : `${steps[props.step].form.inputStyle.width}%`,
                                padding : `${steps[props.step].form.inputStyle.padding}px`,
                                fontSize : `${steps[props.step].form.inputStyle.fontSize}px`,
                                borderRadius : `${steps[props.step].form.inputStyle.borderRadius}px` 
                            }}></input><br/><br/>
                            <label>{steps[props.step].form.lastNameLabel}</label><br/>
                            <input placeholder={steps[props.step].form.lastNameLabel}style={{
                                width : `${steps[props.step].form.inputStyle.width}%`,
                                padding : `${steps[props.step].form.inputStyle.padding}px`,
                                fontSize : `${steps[props.step].form.inputStyle.fontSize}px`,
                                borderRadius : `${steps[props.step].form.inputStyle.borderRadius}px` 
                            }}></input><br/><br/>
                            <label>{steps[props.step].form.companyLabel}</label><br/>
                            <input placeholder={steps[props.step].form.companyLabel} style={{
                                width : `${steps[props.step].form.inputStyle.width}%`,
                                padding : `${steps[props.step].form.inputStyle.padding}px`,
                                fontSize : `${steps[props.step].form.inputStyle.fontSize}px`,
                                borderRadius : `${steps[props.step].form.inputStyle.borderRadius}px` 
                            }}></input><br/><br/>
                            <label>{steps[props.step].form.emailLabel}</label><br/>
                            <input placeholder={steps[props.step].form.emailLabel} style={{
                                width : `${steps[props.step].form.inputStyle.width}%`,
                                padding : `${steps[props.step].form.inputStyle.padding}px`,
                                fontSize : `${steps[props.step].form.inputStyle.fontSize}px`,
                                borderRadius : `${steps[props.step].form.inputStyle.borderRadius}px` 
                            }}></input><br/><br/>
                            <button onClick={()=>{props.setStep(props.step + 1)}} style={{
                                fontSize : `${steps[props.step].form.ctaStyle.fontSize}px`,
                                color : steps[props.step].form.ctaStyle.color,
                                backgroundColor : steps[props.step].form.ctaStyle.backgroundColor,
                                padding : `${steps[props.step].form.ctaStyle.padding}px`,
                                borderRadius : `${steps[props.step].form.ctaStyle.borderRadius}px`,
                                border : "none"

                            }}>{steps[props.step].form.ctaText}</button>
                         </div>
                        :<Container style={{width :"100%"}}>
                            <Typography variant="h6">{steps[props.step].form.header}</Typography>
                            <Typography>{steps[props.step].form.description}</Typography>
                            <InputLabel id="firstName-label">{steps[props.step].form.firstNameLabel}</InputLabel>
                            {(steps[props.step].form.firstNameLabel.length > 0)?<span><TextField id="firstName" placeholder={steps[props.step].form.firstNameLabel} /><br/><br/></span>:null}
                            <InputLabel id="lastName-label">{steps[props.step].form.lastNameLabel}</InputLabel>
                            {(steps[props.step].form.lastNameLabel.length > 0)?<span><TextField id="lastName" placeholder={steps[props.step].form.lastNameLabel} /><br/><br/></span>:null}
                            <InputLabel id="company-label">{steps[props.step].form.companyLabel}</InputLabel>
                            {(steps[props.step].form.companyLabel.length > 0)?<span><TextField id="company" placeholder={steps[props.step].form.companyLabel} /><br/><br/></span>:null}
                            <InputLabel id="email-label">{steps[props.step].form.emailLabel}</InputLabel>
                            {(steps[props.step].form.emailLabel.length >0)?<span><TextField id="email" placeholder={steps[props.step].form.emailLabel} /><br/><br/></span>:null}
                            {(steps[props.step].form.ctaText.length > 0)?<Button variant="contained" onClick={()=>{props.setStep(props.step + 1)}}>{steps[props.step].form.ctaText}</Button>:null}
                        </Container>   
                    }
                 </>
                : <Typography>Preview will appear here once you've saved your first step</Typography> 
            }
        </div>
    );
}

export const DisplayEnd = (props : any) : React.ReactElement => {
    const {steps} = useContext(Context);

    return(
        <div style={{ width : "95%", height : "100%"}}>
            {
                (steps[props.step].thankYouPage)
                ?<>
                    {
                        (steps[props.step].thankYouPage.textScreenStyle)
                        ?<><div style={{
                            textAlign : steps[props.step].thankYouPage.textScreenStyle.textAlign, 
                            padding : `${steps[props.step].thankYouPage.textScreenStyle.padding}px`, 
                            backgroundColor : steps[props.step].thankYouPage.textScreenStyle.backgroundColor,
                            height : "100%",
                            width : "100%"
                        }}>
                            <h2 style={{
                                fontSize : `${steps[props.step].thankYouPage.h1Style.fontSize}px`, 
                                color : steps[props.step].thankYouPage.h1Style.color
                            }}>{steps[props.step].thankYouPage.h1}</h2>
                            <p style={{
                                fontSize : `${steps[props.step].thankYouPage.pStyle.fontSize}px`,
                                color : steps[props.step].thankYouPage.pStyle.color
                            }}>{steps[props.step].thankYouPage.description}</p>
                            <button onClick={()=>{props.setStep(props.step + 1)}} style={{
                                fontSize : `${steps[props.step].thankYouPage.ctaStyle.fontSize}px`,
                                color : steps[props.step].thankYouPage.ctaStyle.color,
                                backgroundColor : steps[props.step].thankYouPage.ctaStyle.backgroundColor,
                                padding : `${steps[props.step].thankYouPage.ctaStyle.padding}px`,
                                borderRadius : `${steps[props.step].thankYouPage.ctaStyle.borderRadius}px`,
                                border : "none"
                            }}>{steps[props.step].thankYouPage.ctaText}</button>
                        </div>
                            <hr style={{width : "100%", borderTop : "2px solid grey", marginBottom : "20px"}}/>
                            <Typography style={{textAlign : "left"}}>This is the end of your sequence preview.</Typography><br/>
                            <Button style={{textAlign : "center"}} variant="contained" onClick={()=>{props.setStep(0)}}>Return to beginning from here</Button>
                        </>
                        :<Container>
                            <Typography variant="h5">{steps[props.step].thankYouPage.h1}</Typography><br/>
                            <Typography>{steps[props.step].thankYouPage.description}</Typography><br/>
                            <Button variant="contained">{steps[props.step].thankYouPage.ctaText}</Button>
                            <br/><br/>
                            <hr style={{width : "100%", borderTop : "2px solid grey"}}/>
                            <Typography>This is the end of your sequence preview.</Typography>
                            <Button variant="contained" onClick={()=>{props.setStep(0)}}>Return to beginning from here</Button>
                        </Container>
                    }
                </>
                :<Container>
                    <Typography>There was an error...</Typography>
                    <Button variant="contained">Return to beginning</Button>
                </Container>
            }
        </div>
    );
}

const NewSequencePreview : React.FC = () : React.ReactElement => {
    const [currentStep, setCurrentStep] = useState(0);
    const {steps} = useContext(Context);

    return(
        <div>
            <Typography variant="h6">Preview your sequence here</Typography>
            <div className="previewBox">
                {
                    (steps.length > 0)
                    ? (steps[currentStep]?.action === "video")
                        ? <DisplayVideo step={currentStep} setStep={setCurrentStep}/>
                        : (steps[currentStep]?.action === "form")
                            ? <DisplayForm step={currentStep} setStep={setCurrentStep} />
                            : (steps[currentStep]?.action ==="end screen")
                                ? <DisplayEnd step={currentStep} setStep={setCurrentStep}/>
                                : <Container>
                                    <Typography variant="h4">Your sequence has ended</Typography><br/>
                                    <Button variant="contained" color="secondary" onClick={()=>{setCurrentStep(0)}}>Return to beginning</Button>
                                </Container>
                    : <Typography>There was an unexpected error...</Typography>
                }
            </div>
        </div>
    );
}

export default NewSequencePreview;