import React, { useContext, useEffect, useRef, useState } from "react";
import { Context, Video, VideoStyle, textScreenStyle, TextStyle, ctaStyle } from "./Context";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, ButtonGroup, Checkbox, Container, FormControlLabel, InputAdornment, InputLabel, OutlinedInput, Tab, Tabs, TextField, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SegmentIcon from '@mui/icons-material/Segment';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';

const VideoSelection = (props : any) : React.ReactElement => {
    const {steps, setSteps} = useContext(Context);
    const [video, setVideo] = useState<Video>(
        {url: "", 
        poster : "", 
        title : "", 
        description : "", 
        ctaText : "", 
        showTimeLeft : false, 
        timeLeftPersentage : 50, 
        timeLeftText : ""
    });
    const [expand, setExpand] = useState<boolean>(true);
    const [preview, setPreview] = useState({textScreen: false, video: false});
    const videoRef = useRef<HTMLVideoElement>(null);
    const [timeLeftMessage, setTimeLeftMessage] = useState<boolean>(false);
    const [timeLeft, setTimeLeft] = useState<number>(0);
    const [tabValue, setTabvalue] = useState<number>(0);
    const [videoStyle, setVideoStyle] = useState<VideoStyle>({width : "100%", borderRadius : ""});
    const [textScreenStyle, setTextScreenStyle] = useState<textScreenStyle>({textAlign : "", padding : "", backgroundColor : ""});
    const [h1Style, setH1style] = useState<TextStyle>({fontSize : "", color : ""});
    const [pStyle, setPStyle] = useState<TextStyle>({fontSize : "", color : ""});
    const [ctaStyle, setCtaStyle] = useState<ctaStyle>({fontSize : "", color: "", backgroundColor : "", padding : "", borderRadius : ""});

    const updateValues = () => {
        if(steps[props.idx].video){
            setVideo(steps[props.idx].video);

            if(steps[props.idx].video.videoStyle){
                setVideoStyle(steps[props.idx].video.videoStyle);
            }
            if(steps[props.idx].video.textScreenStyle){
                setTextScreenStyle(steps[props.idx].video.textScreenStyle);
            }
            if(steps[props.idx].video.h1Style){
                setH1style(steps[props.idx].video.h1Style);
            }
            if(steps[props.idx].video.pStyle){
                setPStyle(steps[props.idx].video.pStyle);
            }
            if(steps[props.idx].video.ctaStyle){
                setCtaStyle(steps[props.idx].video.ctaStyle);
            }
            
            if(steps[props.idx].video.description !== "" && steps[props.idx].video.title !== "" && steps[props.idx].video.ctaText !== ""){
                setPreview({...preview, textScreen: true});
            }else{
                if(steps[props.idx].video.url !== ""){
                    setPreview({...preview, video: true});
                }
            }

        }
    }

    const handleURLchange = (e : string) => {
        setVideo({...video, url : e});
    }

    const TextPreview = () => {
        if(video.title.length > 0 && video.description.length > 0 && video.ctaText.length > 0){
            setPreview({...preview, textScreen : true, video : false});
        }
    }

    const handleStyleChange = () => {
        setVideo({...video, videoStyle : videoStyle, textScreenStyle : textScreenStyle, h1Style : h1Style, pStyle : pStyle, ctaStyle : ctaStyle});
    }

    const saveVideo = () => {
        handleStyleChange();
        console.log(video);
        steps[props.idx].video = video;
        setExpand(false);
    }

    const removeStep = () => {
        setSteps(steps.filter((step : any) => step !== steps[props.idx]));
        props.setAccord(props.accord - 1);
    }

    useEffect(()=>{
        updateValues();
    }, []);

    const DisplayLeftTime = () =>{
        if(videoRef.current?.currentTime){
            if(videoRef.current.duration){
                if(video.timeLeftPersentage){
                    if((videoRef.current.currentTime / videoRef.current.duration) * 100 > video.timeLeftPersentage && timeLeftMessage === false){
                        setTimeLeftMessage(true);
                    }
                    if((videoRef.current.currentTime / videoRef.current.duration) * 100 < video.timeLeftPersentage && timeLeftMessage === true){
                        setTimeLeftMessage(false);
                    }
                    setTimeLeft(Number((videoRef.current.duration - videoRef.current.currentTime).toFixed(0)));
                }
            }
        }
    }

    return(
        <Accordion key={props.idx} expanded={expand} style={{boxShadow: "0px 0px 3px 1px grey"}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={()=>{setExpand(!expand)}}>
                <Typography sx={{width : "15%", flexShrink : 0}}>Step {props.idx + 1} : {steps[props.idx].action}</Typography>
                <Typography sx={{color: "text.secondary"}}>{video.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div className="StepSelectionFlexBox">
                    <div className="StepSelection">
                        <Box sx={{borderBottom : 1, borderColor : "divider", marginBottom : "20px"}}>
                            <Tabs value={ tabValue }>
                                <Tab icon={<SegmentIcon />} iconPosition="start" label="Content" onClick={()=>{setTabvalue(0); handleStyleChange();}} />
                                <Tab icon={<InvertColorsIcon />} iconPosition="start" label="Style" onClick={()=>{setTabvalue(1);}} />
                            </Tabs>
                        </Box>
                        {
                            (tabValue === 0)
                            ? <div>
                                <Typography variant="h6">Insert your video:</Typography>
                                <br/>
                                <div className="StepDetailsFlex">
                                    <div style={{width : "45%"}}>
                                        <InputLabel id="url-label" style={{marginTop : "10px"}}>
                                            URL to your video:
                                        </InputLabel>
                                        <TextField id="url" placeholder="URL" variant="outlined" required fullWidth 
                                        value={video.url}
                                        onChange={(e)=>{handleURLchange(e.target.value as string); setPreview({...preview, video : true})}}/><br/>
                                    </div>
                                    <div style={{width : "45%"}}>
                                        <InputLabel id="poster-label" style={{marginTop : "10px"}}>
                                            <b>Cover image</b> URL:
                                        </InputLabel>
                                        <TextField id="poster" placeholder="Cover image URL" variant="outlined" required fullWidth 
                                        value={video.poster}
                                        onChange={(e)=>{setVideo({...video, poster : e.target.value as string}); setPreview({...preview, video : true})}}/><br/>
                                    </div>
                                </div>
                                <FormControlLabel 
                                label="Show how many seconds until next video" 
                                control={<Checkbox checked={video.showTimeLeft} onChange={()=>{setVideo({...video, showTimeLeft : !video.showTimeLeft})}}/>}
                                />
                                {
                                    (video.showTimeLeft)
                                    ?<div className="StepDetailsFlex">
                                        <div style={{width : "45%"}}>
                                            <InputLabel id="timeleft-text-label">Customize your "time left" -message:</InputLabel>
                                            <TextField id="timetleft-text" placeholder="Time left until next video" fullWidth value={video.timeLeftText}
                                            onChange={(e)=>{setVideo({...video, timeLeftText : e.target.value as string})}}
                                            />
                                        </div>
                                        <div style={{width : "45%"}}>
                                            <InputLabel id="timeleft-text-label">Show message when x% watched</InputLabel>
                                            <TextField id="timetleft-text" placeholder="Example: 80 (%)" fullWidth type="number"value={video.timeLeftPersentage}
                                            onChange={(e)=>{setVideo({...video, timeLeftPersentage : Number(e.target.value)})}}
                                            />
                                        </div>
                                    </div>
                                    :null
                                }
                                <br/><br/>
                                <Typography variant="h6">Video details 	&#40;Optional&#41;:</Typography><br/>
                                <div className="StepDetailsFlex">
                                    <div style={{width:"40%"}}>
                                        <InputLabel id="title-label">Title of your video:</InputLabel>
                                        <TextField id="title" placeholder="title..." variant="outlined" required fullWidth
                                        value={video.title}
                                        onChange={(e)=>{setVideo({...video, title : e.target.value as string}); TextPreview();}}/><br/><br/>
                                        <InputLabel id="ctaText-label">Call to action to start your video:</InputLabel>
                                        <TextField id="ctaText" placeholder="CTA text..." variant="outlined" required fullWidth
                                        value={video.ctaText}
                                        onChange={(e)=>{setVideo({...video, ctaText : e.target.value as string}); TextPreview();}}/><br/><br/>
                                    </div>
                                    <div style={{width:"55%"}}>
                                        <InputLabel id="description-label">Description for the viewer:</InputLabel>
                                        <TextField id="description" placeholder="description..." required multiline rows={5} variant="outlined" fullWidth
                                        value={video.description}
                                        onChange={(e)=>{setVideo({...video, description : e.target.value as string}); TextPreview();}}/><br/><br/>
                                    </div>
                                </div>
                                <Button variant="outlined" color="secondary" onClick={saveVideo} style={{margin : "0px 10px 0px 0px"}}><b>Save video step</b></Button> 
                                <Button variant="contained" style={{margin : "0px 0px 0px 10px"}} color="error" onClick={removeStep}>Remove this step</Button>
                            </div>
                            : null
                        }
                        {
                            (tabValue === 1)
                            ?<div>
                                <div className="StepDetailsFlex" style={{marginBottom : "20px"}}>
                                    <div style={{width:"30%"}}>
                                        <InputLabel htmlFor="video-width">Video width:</InputLabel>
                                        <OutlinedInput id="video-widht" endAdornment={<InputAdornment position="end">%</InputAdornment>} type="number"
                                        value={videoStyle.width} onChange={(e)=>{setVideoStyle({...videoStyle, width : e.target.value}); handleStyleChange();}} placeholder="100" />
                                    </div>
                                    <div style={{width:"30%"}}>
                                        <InputLabel htmlFor="border-radius">Border radius:</InputLabel>
                                        <OutlinedInput id="border-radius" endAdornment={<InputAdornment position="end">px</InputAdornment>} type="number"
                                        value={videoStyle.borderRadius} onChange={(e)=>{setVideoStyle({...videoStyle, borderRadius : e.target.value}); handleStyleChange();}} placeholder="100" />
                                    </div>
                                    <div style={{width:"30%"}}>

                                    </div>
                                </div>
                                <div className="seperator">Text screen styling</div>
                                <div className="StepDetailsFlex" style={{marginTop : "20px"}}>
                                    <div style={{width:"30%"}}>
                                        <InputLabel htmlFor="video-width">Text align:</InputLabel>
                                        <ButtonGroup size="large">
                                            {
                                                (textScreenStyle.textAlign === "left")
                                                ?<Button onClick={()=>{setTextScreenStyle({...textScreenStyle, textAlign : "left"}); handleStyleChange();}} variant="contained">
                                                    <FormatAlignLeftIcon />
                                                </Button>
                                                :<Button onClick={()=>{setTextScreenStyle({...textScreenStyle, textAlign : "left"}); handleStyleChange();}}>
                                                    <FormatAlignLeftIcon />
                                                </Button>
                                            }
                                            {
                                                (textScreenStyle.textAlign === "center")
                                                ?<Button onClick={()=>{setTextScreenStyle({...textScreenStyle, textAlign : "center"}); handleStyleChange();}} variant="contained">
                                                    <FormatAlignCenterIcon />
                                                </Button>
                                                :<Button onClick={()=>{setTextScreenStyle({...textScreenStyle, textAlign : "center"}); handleStyleChange();}}>
                                                    <FormatAlignCenterIcon />
                                                </Button>
                                            }
                                            {
                                                (textScreenStyle.textAlign === "right")
                                                ?<Button onClick={()=>{setTextScreenStyle({...textScreenStyle, textAlign : "right"}); handleStyleChange();}} variant="contained">
                                                    <FormatAlignRightIcon />
                                                </Button>
                                                :<Button onClick={()=>{setTextScreenStyle({...textScreenStyle, textAlign : "right"}); handleStyleChange();}}>
                                                    <FormatAlignRightIcon />
                                                </Button>
                                            }
                                        </ButtonGroup>
                                    </div>
                                    <div style={{width:"30%"}}>
                                        <InputLabel htmlFor="padding">Text screen padding: </InputLabel>
                                        <OutlinedInput id="padding" endAdornment={<InputAdornment position="end">px</InputAdornment>} type="number"
                                        value={textScreenStyle.padding} onChange={(e)=>{setTextScreenStyle({...textScreenStyle, padding : e.target.value}); handleStyleChange();}} placeholder="20" />
                                    </div>
                                    <div style={{width:"30%"}}>
                                        <InputLabel htmlFor="bg-color">Background:</InputLabel>
                                        <input type="color" id="bg-color" value={textScreenStyle.backgroundColor} style={{width : "50%", height : "50%"}}
                                        onChange={(e)=>{setTextScreenStyle({...textScreenStyle, backgroundColor : e.target.value as string}); handleStyleChange();}}/>
                                    </div>
                                </div>
                                <div className="seperator">Title styling</div>
                                <div className="StepDetailsFlex" style={{marginBottom : "20px", marginTop : "20px"}}>
                                    <div style={{width:"30%"}}>
                                        <InputLabel htmlFor="h1-size">Title font size:</InputLabel>
                                        <OutlinedInput id="h1-size" endAdornment={<InputAdornment position="end">px</InputAdornment>} type="number"
                                        value={h1Style.fontSize} onChange={(e)=>{setH1style({...h1Style, fontSize : e.target.value}); handleStyleChange();}} placeholder="25" />
                                    </div>
                                    <div style={{width:"30%"}}>
                                        <InputLabel htmlFor="h1-color">Font color:</InputLabel>
                                        <input type="color" id="h1-color" value={h1Style.color} style={{width : "50%", height : "50%"}}
                                        onChange={(e)=>{setH1style({...h1Style, color : e.target.value as string}); handleStyleChange();}}/>
                                    </div>
                                    <div style={{width:"30%"}}>

                                    </div>
                                </div>
                                <div className="seperator">Description styling</div>
                                <div className="StepDetailsFlex" style={{marginBottom : "20px"}}>
                                    <div style={{width:"30%"}}>
                                        <InputLabel htmlFor="p-size">Description font size:</InputLabel>
                                        <OutlinedInput id="p-size" endAdornment={<InputAdornment position="end">px</InputAdornment>} type="number"
                                        value={pStyle.fontSize} onChange={(e)=>{setPStyle({...pStyle, fontSize : e.target.value as string}); handleStyleChange();}} placeholder="18" />
                                    </div>
                                    <div style={{width:"30%"}}>
                                        <InputLabel htmlFor="p-color">Font color:</InputLabel>
                                        <input type="color" id="p-color" value={pStyle.color} style={{width : "50%", height : "50%"}}
                                        onChange={(e)=>{setPStyle({...pStyle, color : e.target.value as string}); handleStyleChange();}}/>
                                    </div>
                                    <div style={{width:"30%"}}>

                                    </div>
                                </div>
                                <div className="seperator">CTA styling</div>
                                <div className="StepDetailsFlex" style={{marginBottom : "20px"}}>
                                    <div style={{width:"15%"}}>
                                        <InputLabel htmlFor="cta-size" style={{fontSize : "14px"}}>Font size:</InputLabel>
                                        <OutlinedInput id="cta-padding" endAdornment={<InputAdornment position="end">px</InputAdornment>} type="number"
                                        value={ctaStyle.fontSize} onChange={(e)=>{setCtaStyle({...ctaStyle, fontSize : e.target.value as string}); handleStyleChange();}} placeholder="18" />
                                    </div>
                                    <div style={{width:"15%"}}>
                                        <InputLabel htmlFor="cta-padding" style={{fontSize : "14px"}}>Padding:</InputLabel>
                                        <OutlinedInput id="cta-padding" endAdornment={<InputAdornment position="end">px</InputAdornment>} type="number"
                                        value={ctaStyle.padding} onChange={(e)=>{setCtaStyle({...ctaStyle, padding : e.target.value as string}); handleStyleChange();}} placeholder="10" />
                                    </div>
                                    <div style={{width:"20%"}}>
                                        <InputLabel htmlFor="cta-radius" style={{fontSize : "14px"}}>Border radius:</InputLabel>
                                        <OutlinedInput id="cta-radius" endAdornment={<InputAdornment position="end">px</InputAdornment>} type="number"
                                        value={ctaStyle.borderRadius} onChange={(e)=>{setCtaStyle({...ctaStyle, borderRadius : e.target.value as string}); handleStyleChange();}} placeholder="10" />
                                    </div>
                                    <div style={{width:"15%"}}>
                                        <InputLabel htmlFor="cta-color" style={{fontSize : "14px"}}>Font color:</InputLabel>
                                        <input type="color" id="cta-padding" value={ctaStyle.color} style={{width : "100%", height : "50%"}}
                                        onChange={(e)=>{setCtaStyle({...ctaStyle, color : e.target.value as string}); handleStyleChange();}}/>
                                    </div>
                                    <div style={{width:"18%"}}>
                                        <InputLabel htmlFor="cta-padding" style={{fontSize : "14px"}}>Background:</InputLabel>
                                        <input type="color" id="cta-padding" value={ctaStyle.backgroundColor} style={{width : "100%", height : "50%"}}
                                        onChange={(e)=>{setCtaStyle({...ctaStyle, backgroundColor : e.target.value as string}); handleStyleChange();}}/>
                                    </div>
                                </div>
                             </div>
                            : null
                        }

                    </div>
                    <div className="StepScreen">
                        {
                            (!preview.textScreen && !preview.video)
                            ? <Container style={
                                {border:"1px solid grey", boxShadow : "0px 1px 2px 1px grey", padding: "10px", height : "300px", display : "flex", justifyContent : "center", alignItems : "center", borderRadius : "5px"}
                            }>
                                <Typography>Fill in your video infromation to enable preview...</Typography>   
                            </Container>
                            : <Container style={
                                {border:"1px solid grey", boxShadow : "0px 1px 2px 1px grey", padding: "10px", display : "flex", flexWrap : "wrap", justifyContent : "center", alignItems : "center", borderRadius : "5px"}
                            }>
                                {
                                    (preview.textScreen)
                                    ?<>
                                        {
                                            (textScreenStyle.textAlign === "left" || textScreenStyle.textAlign === "center" || textScreenStyle.textAlign === "right")
                                            ?<div 
                                            style={{width : "100%", padding : `${textScreenStyle.padding}px`, 
                                            textAlign : textScreenStyle.textAlign, backgroundColor : textScreenStyle.backgroundColor
                                            }}
                                            >
                                                <h1 style={{fontSize : `${h1Style.fontSize}px`, color : h1Style.color}}>{video.title}</h1>
                                                <p style={{fontSize : `${pStyle.fontSize}px`, color : pStyle.color}}>{video.description}</p>
                                                <button style={{fontSize : `${ctaStyle.fontSize}px`, color : ctaStyle.color, borderRadius : `${ctaStyle.borderRadius}px`,
                                                padding : `${ctaStyle.padding}px`, backgroundColor : ctaStyle.backgroundColor, border : "none"
                                                }} 
                                                onClick={()=>{setPreview({...preview, textScreen: false, video: true})}}>{video.ctaText}</button>
                                             </div>
                                            : <div style={{width : "100%", padding : `${textScreenStyle.padding}px`, backgroundColor : textScreenStyle.backgroundColor}}>
                                                <h1 style={{fontSize : `${h1Style.fontSize}px`, color : h1Style.color}}>{video.title}</h1>
                                                <p style={{fontSize : `${pStyle.fontSize}px`, color : pStyle.color}}>{video.description}</p>
                                                <button style={{fontSize : `${ctaStyle.fontSize}px`, color : ctaStyle.color, borderRadius : `${ctaStyle.borderRadius}px`,
                                                padding : `${ctaStyle.padding}px`, backgroundColor : ctaStyle.backgroundColor, border : "none"
                                                }} 
                                                onClick={()=>{setPreview({...preview, textScreen: false, video: true})}}>{video.ctaText}</button>
                                             </div>
                                        }
                                    </>
                                    : null
                                }
                                {
                                    (preview.video)
                                    ? <Container style={{width: "100%"}}>
                                        <video src={video.url} width={`${videoStyle.width}%`} style={{borderRadius : `${videoStyle.borderRadius}px`}} controls  ref={videoRef} poster={video.poster || ""}
                                        onTimeUpdate={()=>{DisplayLeftTime();}}>
                                        Something went wrong with your video...</video>
                                        {
                                            (timeLeftMessage)
                                            ?<Typography style={{textAlign : "right"}}>{video.timeLeftText}: {`${timeLeft}s`} </Typography>
                                            :null
                                        }
                                      </Container>
                                    :null
                                }<br/>
                                {
                                    (preview.video && !preview.textScreen && video.description.length > 0 && video.ctaText.length > 0 && video.title.length > 0)
                                    ? <Button variant="outlined" onClick={()=>{setPreview({...preview, textScreen: true, video : false})}}>Restart preview</Button>
                                    : null
                                }
                            </Container>
                        }
                        <br/>
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>
    );
}

export default VideoSelection;