import React, { useContext, useEffect, useState } from "react";
import { Context, Steps } from "../components/Context";
import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Avatar, Box, Button, ButtonGroup, Container, Fade, List, ListItem, ListItemAvatar, ListItemText, Modal, Paper, TextField, Tooltip, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PercentIcon from '@mui/icons-material/Percent';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { Gauge, LineChart } from "@mui/x-charts";
import { isSameDay } from "date-fns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import { DataGrid } from "@mui/x-data-grid";
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';

export const VideoResults = (props : any) : React.ReactElement => {

    return(
        <div>
            {
                (props.totals && props.totals.length > 0 && props.showNewView)
                ?<Box style={{display :"flex", flexWrap : "wrap"}}>
                    <Paper elevation={5} 
                    style={{margin: "10px", padding: "20px 5px 20px 5px", flex: "10%", justifyContent : "center", alignItems :"center", display : "flex"}}>
                        <div className="resultCardFlex">
                            <div className="resultIcon">
                                <PlayCircleFilledWhiteIcon style={{fontSize: "60px", color : "#323377"}}/>
                            </div>
                            <div className="resultText">
                                <Typography style={{fontSize: "16px"}}>Video starts:</Typography>
                                <Typography variant="h4">{props.totals[0][`step${props.idx}_start`]}</Typography>
                            </div>
                        </div>
                    </Paper>
                    <Paper elevation={5} 
                    style={{margin: "10px", padding: "20px 5px 20px 5px", flex: "10%", justifyContent : "center", alignItems :"center", display : "flex"}}>
                        <div className="resultCardFlex">
                            <div className="resultIcon">
                                <CheckCircleIcon style={{fontSize: "60px", color : "#00D3A8"}}/>
                            </div>
                            <div className="resultText">
                                <Typography style={{fontSize: "16px"}}>Video finishes:</Typography>
                                <Typography variant="h4">{props.totals[0][`step${props.idx}_finish`]}</Typography>
                                <Typography sx={{fontSize: "16px"}}>completion: {`${((props.totals[0][`step${props.idx}_finish`] / props.totals[0][`step${props.idx}_start`]) * 100).toFixed(2)} %`}</Typography>
                            </div>
                        </div>
                    </Paper>
                    <Paper elevation={5} 
                    style={{margin: "10px", padding: "20px 5px 20px 5px", flex: "10%", justifyContent : "center", alignItems :"center", display : "flex"}}>
                        <div className="resultCardFlex">
                            <div className="resultIcon">
                                <StopCircleIcon style={{fontSize: "60px", color : "#FFBA5B"}}/>
                            </div>
                            <div className="resultText">
                                <Typography style={{fontSize: "16px"}}>*Drop off point:</Typography>
                                <Typography variant="h4">{`${(props.totals[0][`step${props.idx}_pause`][1] / props.totals[0][`step${props.idx}_pause`][0]).toFixed(2)} %`}</Typography>
                                <Typography sx={{fontSize:"16px"}}>Lost {(props.totals[0][`step${props.idx}_pause`][0])} visiors</Typography>
                            </div>
                        </div>
                    </Paper>
                    <div style={{flex : "100%"}}>
                        <Typography style={{margin: "10px"}}>
                            *Drop off point = After what % of the video your users leave your funnel
                        </Typography>
                    </div>
                </Box>
                :<Box style={{display :"flex", flexWrap : "wrap"}}>
                    <Paper elevation={5} 
                    style={{margin: "10px", padding: "20px 5px 20px 5px", flex: "10%", justifyContent : "center", alignItems :"center", display : "flex"}}>
                        <div className="resultCardFlex">
                            <div className="resultIcon">
                                <PlayCircleFilledWhiteIcon style={{fontSize: "60px", color : "#323377"}}/>
                            </div>
                            <div className="resultText">
                                <Typography style={{fontSize: "16px"}}>Video starts:</Typography>
                                <Typography variant="h4">{props.magnet.starts}</Typography>
                            </div>
                        </div>
                    </Paper>
                    <Paper elevation={5} 
                    style={{margin: "10px", padding: "20px 5px 20px 5px", flex: "10%", justifyContent : "center", alignItems :"center", display : "flex"}}>
                        <div className="resultCardFlex">
                            <div className="resultIcon">
                                <CheckCircleIcon style={{fontSize: "60px", color : "#00D3A8"}}/>
                            </div>
                            <div className="resultText">
                                <Typography style={{fontSize: "16px"}}>Video finishes:</Typography>
                                <Typography variant="h4">{props.magnet.finish}</Typography>
                            </div>
                        </div>
                    </Paper>
                    <Paper elevation={5} 
                    style={{margin: "10px", padding: "20px 5px 20px 5px", flex: "10%", justifyContent : "center", alignItems :"center", display : "flex"}}>
                        <div className="resultCardFlex">
                            <div className="resultIcon">
                                <OndemandVideoIcon style={{fontSize: "60px", color : "#FFBA5B"}}/>
                            </div>
                            <div className="resultText">
                                <Typography style={{fontSize: "16px"}}>Completion ratio:</Typography>
                                <Typography variant="h4">{`${((props.magnet.finish / props.magnet.starts) * 100).toFixed(2)} %`}</Typography>
                            </div>
                        </div>
                    </Paper>
                </Box>
            }
        </div>
    );
}

export const FormResulsts = (props : any) : React.ReactElement => {

    return(
        <div>
            {
                (props.totals && props.totals.length > 0 && props.showNewView)
                ?<Box style={{display :"flex", flexWrap : "wrap"}}>
                    <Paper elevation={5} 
                    style={{margin: "10px", padding: "20px 5px 20px 5px", flex: "10%", justifyContent : "center", alignItems :"center", display : "flex"}}>
                        <div className="resultCardFlex">
                            <div className="resultIcon">
                                <VisibilityIcon style={{fontSize: "60px", color : "#323377"}}/>
                            </div>
                            <div className="resultText">
                                <Typography style={{fontSize: "18px"}}>Form views:</Typography>
                                <Typography variant="h4">{props.totals[0][`step${props.idx}_start`]}</Typography>
                            </div>
                        </div>
                    </Paper>
                    <Paper elevation={5} 
                    style={{margin: "10px", padding: "20px 5px 20px 5px", flex: "10%", justifyContent : "center", alignItems :"center", display : "flex"}}>
                        <div className="resultCardFlex">
                            <div className="resultIcon">
                                <PermIdentityIcon style={{fontSize: "60px", color : "#00D3A8"}}/>
                            </div>
                            <div className="resultText">
                                <Typography style={{fontSize: "18px"}}>Contacts collected:</Typography>
                                <Typography variant="h4">{props.totals[0][`step${props.idx}_finish`]}</Typography>
                            </div>
                        </div>
                    </Paper>
                    <Paper elevation={5} 
                    style={{margin: "10px", padding: "20px 5px 20px 5px", flex: "10%", justifyContent : "center", alignItems :"center", display : "flex"}}>
                        <div className="resultCardFlex">
                            <div className="resultIcon">
                                <PercentIcon style={{fontSize: "60px", color : "#FFBA5B"}}/>
                            </div>
                            <div className="resultText">
                                <Typography style={{fontSize: "16px"}}>Conversion ratio:</Typography>
                                <Typography variant="h4">{`${((props.totals[0][`step${props.idx}_finish`]/ props.totals[0][`step${props.idx}_start`]) * 100).toFixed(2)} %`}</Typography>
                            </div>
                        </div>
                    </Paper>
                </Box>
                :<Box style={{display :"flex", flexWrap : "wrap"}}>
                    <Paper elevation={5} 
                    style={{margin: "10px", padding: "20px 5px 20px 5px", flex: "10%", justifyContent : "center", alignItems :"center", display : "flex"}}>
                        <div className="resultCardFlex">
                            <div className="resultIcon">
                                <VisibilityIcon style={{fontSize: "60px", color : "#323377"}}/>
                            </div>
                            <div className="resultText">
                                <Typography style={{fontSize: "18px"}}>Form views:</Typography>
                                <Typography variant="h4">{props.magnet.starts}</Typography>
                            </div>
                        </div>
                    </Paper>
                    <Paper elevation={5} 
                    style={{margin: "10px", padding: "20px 5px 20px 5px", flex: "10%", justifyContent : "center", alignItems :"center", display : "flex"}}>
                        <div className="resultCardFlex">
                            <div className="resultIcon">
                                <PermIdentityIcon style={{fontSize: "60px", color : "#00D3A8"}}/>
                            </div>
                            <div className="resultText">
                                <Typography style={{fontSize: "18px"}}>Contacts collected:</Typography>
                                <Typography variant="h4">{props.magnet.finish}</Typography>
                            </div>
                        </div>
                    </Paper>
                    <Paper elevation={5} 
                    style={{margin: "10px", padding: "20px 5px 20px 5px", flex: "10%", justifyContent : "center", alignItems :"center", display : "flex"}}>
                        <div className="resultCardFlex">
                            <div className="resultIcon">
                                <PercentIcon style={{fontSize: "60px", color : "#FFBA5B"}}/>
                            </div>
                            <div className="resultText">
                                <Typography style={{fontSize: "16px"}}>Conversion ratio:</Typography>
                                <Typography variant="h4">{`${((props.magnet.finish / props.magnet.starts) * 100).toFixed(2)} %`}</Typography>
                            </div>
                        </div>
                    </Paper>
                </Box>
            }
        </div>
    );
}

export const ThxResults = (props : any) : React.ReactElement => {

    return(
        <div>
            {
                (props.totals && props.totals.length > 0 && props.showNewView)
                ?<Box style={{display :"flex", flexWrap : "wrap"}}>
                    <Paper elevation={5} 
                    style={{margin: "10px", padding: "20px 5px 20px 5px", flex: "10%", justifyContent : "center", alignItems :"center", display : "flex"}}>
                        <div className="resultCardFlex">
                            <div className="resultIcon">
                                <VisibilityIcon style={{fontSize: "60px", color : "#323377"}}/>
                            </div>
                            <div className="resultText">
                                <Typography style={{fontSize: "18px"}}>End views:</Typography>
                                <Typography variant="h4">{props.totals[0][`step${props.idx}_start`]}</Typography>
                            </div>
                        </div>
                    </Paper>
                    <Paper elevation={5} 
                    style={{margin: "10px", padding: "20px 5px 20px 5px", flex: "10%", justifyContent : "center", alignItems :"center", display : "flex"}}>
                        <div className="resultCardFlex">
                            <div className="resultIcon">
                                <PermIdentityIcon style={{fontSize: "60px", color : "#00D3A8"}}/>
                            </div>
                            <div className="resultText">
                                <Typography style={{fontSize: "18px"}}>CTA clicks:</Typography>
                                <Typography variant="h4">{props.totals[0][`step${props.idx}_finish`]}</Typography>
                            </div>
                        </div>
                    </Paper>
                    <Paper elevation={5} 
                    style={{margin: "10px", padding: "20px 5px 20px 5px", flex: "10%", justifyContent : "center", alignItems :"center", display : "flex"}}>
                        <div className="resultCardFlex">
                            <div className="resultIcon">
                                <PercentIcon style={{fontSize: "60px", color : "#FFBA5B"}}/>
                            </div>
                            <div className="resultText">
                                <Typography style={{fontSize: "16px"}}>Engagement ratio:</Typography>
                                <Typography variant="h4">{`${((props.totals[0][`step${props.idx}_finish`] / props.totals[0][`step${props.idx}_start`]) * 100).toFixed(2)} %`}</Typography>
                            </div>
                        </div>
                    </Paper>
                </Box>
                :<Box style={{display :"flex", flexWrap : "wrap"}}>
                    <Paper elevation={5} 
                    style={{margin: "10px", padding: "20px 5px 20px 5px", flex: "10%", justifyContent : "center", alignItems :"center", display : "flex"}}>
                        <div className="resultCardFlex">
                            <div className="resultIcon">
                                <VisibilityIcon style={{fontSize: "60px", color : "#323377"}}/>
                            </div>
                            <div className="resultText">
                                <Typography style={{fontSize: "18px"}}>End views:</Typography>
                                <Typography variant="h4">{props.magnet.starts}</Typography>
                            </div>
                        </div>
                    </Paper>
                    <Paper elevation={5} 
                    style={{margin: "10px", padding: "20px 5px 20px 5px", flex: "10%", justifyContent : "center", alignItems :"center", display : "flex"}}>
                        <div className="resultCardFlex">
                            <div className="resultIcon">
                                <PermIdentityIcon style={{fontSize: "60px", color : "#00D3A8"}}/>
                            </div>
                            <div className="resultText">
                                <Typography style={{fontSize: "18px"}}>CTA clicks:</Typography>
                                <Typography variant="h4">{props.magnet.finish}</Typography>
                            </div>
                        </div>
                    </Paper>
                    <Paper elevation={5} 
                    style={{margin: "10px", padding: "20px 5px 20px 5px", flex: "10%", justifyContent : "center", alignItems :"center", display : "flex"}}>
                        <div className="resultCardFlex">
                            <div className="resultIcon">
                                <PercentIcon style={{fontSize: "60px", color : "#FFBA5B"}}/>
                            </div>
                            <div className="resultText">
                                <Typography style={{fontSize: "16px"}}>Engagement ratio:</Typography>
                                <Typography variant="h4">{`${((props.magnet.finish / props.magnet.starts) * 100).toFixed(2)} %`}</Typography>
                            </div>
                        </div>
                    </Paper>
                </Box>
            }
        </div>
    );
}

const LeadMagnet : React.FC = () : React.ReactElement => {
    const { navigate, loadDone, selectedMagnet, deleteSequence, selectedMagnetLeads, openPreview, selectedMagnetData, setSelectedMagnet , editMagnetName} = useContext(Context);
    const [deletePop, setDeletePop] = useState<boolean>(false);
    const [dateData, setDateData] = useState<any>([]);
    const [totals, setTotals] = useState<any>([]);
    const [selectedDays, setSelectedDays] = useState<any>({date1 : "", date2: ""});
    const [keys, setKeys] = useState<any>([]);
    const [showNewView, setShowNewView] = useState<boolean>(false);
    const [dataReady, setDataReady] = useState<boolean>(false);
    const [updateRun, setUpdateRun] = useState<number>(0);
    const [editName, setEditName] = useState<boolean>(false);
    const tableWidth : number = 1000;

    const editDateData = (date1 : Date, date2 : Date) => {
        if(selectedMagnetData && selectedMagnetData.length !== 0){
            const startDate : Date = new Date(date1);
            const endDate : Date = new Date(date2);

            const diff : number = Math.abs(startDate.getTime() - endDate.getTime());
            const diffDays : number = Math.ceil(diff/(1000*60*60*24));

            let dataDays : {[key : string] : any} = [];
            let totalData : {[key:string] : any} = [{id : 0, source : "total" ,views : 0, completions : 0}];

            //This section handles making iterable varibales for all step datas
            for(let i = 0; i <= diffDays; i++){
                const tempDate : Date = new Date(date1);
                const newDate : Date =  new Date(tempDate.setDate(tempDate.getDate() + i));
                dataDays.push({
                    date : newDate,
                    views : 0,
                    completions : 0,
                });
                for(let j = 0; j < selectedMagnet.steps.length; j++){
                    dataDays[i][`step${j}_start`] = 0;
                    dataDays[i][`step${j}_finish`] = 0;
                    totalData[0][`step${j}_start`] = 0;
                    totalData[0][`step${j}_finish`] = 0;
                    if(selectedMagnet.steps[j].action === "video"){
                        dataDays[i][`step${j}_pause`] = [0, 0];
                        totalData[0][`step${j}_pause`] = [0, 0];
                    }
                }
            }

            //This section handles making iterable variables for each source 
            selectedMagnetData.forEach((data : any) => {
                if(data.source !== null && data.source !== undefined){
                    let pass = false
                    totalData.forEach((log : any)=> {
                        if(log.source === data.source){
                            pass = true;
                        }
                    });
                    if(pass === false){
                        totalData.forEach((log : any) => {
                            if(log.source !== data.source && pass === false){
                                let addData : {[key : string] : any} = {};
                                addData.id = totalData.length;
                                addData.source = data.source;
                                addData.views = 0;
                                addData.completions = 0;
                                for(let j = 0; j < selectedMagnet.steps.length; j++){
                                    addData[`step${j}_start`] = 0;
                                    addData[`step${j}_finish`] = 0;
                                }
                                totalData.push(addData);
                                pass = true
                            }
                        })
                    }
                }
            });
            
            //This seciton handles marking down the numbers for all step datas
            for(let i = 0; i < selectedMagnetData.length; i++){
                if(selectedMagnetData[i].step === 0){
                    for(let j = 0; j <= diffDays; j++){
                        if(isSameDay(selectedMagnetData[i].date, dataDays[j].date)){
                            totalData.forEach((log : any)=> {
                                if(log.source === selectedMagnetData[i].source){
                                    if(selectedMagnetData[i].action === "view" && selectedMagnetData[i].step === 0){
                                        log.views += 1
                                    }
                                    if(selectedMagnetData[i].action === "finish" && selectedMagnetData[i].step === selectedMagnet.steps.length -1){
                                        log.completions += 1
                                    }
                                    if(selectedMagnetData[i].action === "start"){
                                        log[`step${selectedMagnetData[i].step}_start`] += 1;
                                    }
                                    if(selectedMagnetData[i].action === "finish"){
                                        log[`step${selectedMagnetData[i].step}_finish`] += 1;
                                    }
                                }
                            });
                            if(selectedMagnetData[i].action === "view"){
                                dataDays[j].views += 1;
                                totalData[0].views += 1;
                            }
                            if(selectedMagnetData[i].action ==="start"){
                                if(dataDays[j][`step${selectedMagnetData[i].step}_start`]){
                                    dataDays[j][`step${selectedMagnetData[i].step}_start`] += 1;
                                }else{
                                    dataDays[j][`step${selectedMagnetData[i].step}_start`] = 1;
                                }
                                if(totalData[0][`step${selectedMagnetData[i].step}_start`]){
                                    totalData[0][`step${selectedMagnetData[i].step}_start`] += 1;
                                }else{
                                    totalData[0][`step${selectedMagnetData[i].step}_start`] = 1;
                                }
                            }
                            if(selectedMagnetData[i].action ==="finish"){
                                if(dataDays[j][`step${selectedMagnetData[i].step}_finish`]){
                                    dataDays[j][`step${selectedMagnetData[i].step}_finish`] += 1;
                                }else{
                                    dataDays[j][`step${selectedMagnetData[i].step}_finish`] = 1;
                                }
                                if(totalData[0][`step${selectedMagnetData[i].step}_finish`]){
                                    totalData[0][`step${selectedMagnetData[i].step}_finish`] += 1;
                                }else{
                                    totalData[0][`step${selectedMagnetData[i].step}_finish`] = 1;
                                }
                            }
                            if(selectedMagnetData[i].action ==="pause"){
                                if(dataDays[j][`step${selectedMagnetData[i].step}_pause`]){
                                    let old = dataDays[j][`step${selectedMagnetData[i].step}_pause`];
                                    old[0] += 1;
                                    old[1] += selectedMagnetData[i].percentage;
                                    dataDays[j][`step${selectedMagnetData[i].step}_pause`] = old;
                                }else{
                                    dataDays[j][`step${selectedMagnetData[i].step}_pause`] = [1, selectedMagnetData[i].percentage];
                                }
                                if(totalData[0][`step${selectedMagnetData[i].step}_pause`]){
                                    let old = totalData[0][`step${selectedMagnetData[i].step}_pause`];
                                    old[0] += 1;
                                    old[1] += selectedMagnetData[i].percentage;
                                    totalData[0][`step${selectedMagnetData[i].step}_pause`] = old;
                                }else{
                                    totalData[0][`step${selectedMagnetData[i].step}_pause`] = [1, selectedMagnetData[i].percentage];
                                }
                            }
                            
                        }
                    }
                }else if(selectedMagnetData[i].step === selectedMagnet.steps.length - 1){
                    for(let j = 0; j <= diffDays; j++){
                        if(isSameDay(selectedMagnetData[i].date, dataDays[j].date)){
                            totalData.forEach((log : any)=> {
                                if(log.source === selectedMagnetData[i].source){
                                    if(selectedMagnetData[i].action === "view" && selectedMagnetData[i].step === 0){
                                        log.views += 1
                                    }
                                    if(selectedMagnetData[i].action === "finish" && selectedMagnetData[i].step === selectedMagnet.steps.length -1){
                                        log.completions += 1
                                    }
                                    if(selectedMagnetData[i].action === "start"){
                                        log[`step${selectedMagnetData[i].step}_start`] += 1;
                                    }
                                    if(selectedMagnetData[i].action === "finish"){
                                        log[`step${selectedMagnetData[i].step}_finish`] += 1;
                                    }
                                }
                            });
                            if(selectedMagnetData[i].action === "finish"){
                                dataDays[j].completions += 1;
                                totalData[0].completions += 1;
                                if(dataDays[j][`step${selectedMagnetData[i].step}_finish`]){
                                    dataDays[j][`step${selectedMagnetData[i].step}_finish`] += 1;
                                }else{
                                    dataDays[j][`step${selectedMagnetData[i].step}_finish`] = 1;
                                }
                                if(totalData[0][`step${selectedMagnetData[i].step}_finish`]){
                                    totalData[0][`step${selectedMagnetData[i].step}_finish`] += 1;
                                }else{
                                    totalData[0][`step${selectedMagnetData[i].step}_finish`] = 1;
                                }
                            }
                            if(selectedMagnetData[i].action ==="start"){
                                if(dataDays[j][`step${selectedMagnetData[i].step}_start`]){
                                    dataDays[j][`step${selectedMagnetData[i].step}_start`] += 1;
                                }else{
                                    dataDays[j][`step${selectedMagnetData[i].step}_start`] = 1;
                                }
                                if(totalData[0][`step${selectedMagnetData[i].step}_start`]){
                                    totalData[0][`step${selectedMagnetData[i].step}_start`] += 1;
                                }else{
                                    totalData[0][`step${selectedMagnetData[i].step}_start`] = 1;
                                }
                            }
                            if(selectedMagnetData[i].action ==="pause"){
                                if(dataDays[j][`step${selectedMagnetData[i].step}_pause`]){
                                    let old = dataDays[j][`step${selectedMagnetData[i].step}_pause`];
                                    old[0] += 1;
                                    old[1] += selectedMagnetData[i].percentage;
                                    dataDays[j][`step${selectedMagnetData[i].step}_pause`] = old;
                                }else{
                                    dataDays[j][`step${selectedMagnetData[i].step}_pause`] = [1, selectedMagnetData[i].percentage];
                                }
                                if(totalData[0][`step${selectedMagnetData[i].step}_pause`]){
                                    let old = totalData[0][`step${selectedMagnetData[i].step}_pause`];
                                    old[0] += 1;
                                    old[1] += selectedMagnetData[i].percentage;
                                    totalData[0][`step${selectedMagnetData[i].step}_pause`] = old;
                                }else{
                                    totalData[0][`step${selectedMagnetData[i].step}_pause`] = [1, selectedMagnetData[i].percentage];
                                }
                            }
                        }
                    }
                }else{
                    for(let j = 0; j <= diffDays; j++){
                        if(isSameDay(selectedMagnetData[i].date, dataDays[j].date)){
                            totalData.forEach((log : any)=> {
                                if(log.source === selectedMagnetData[i].source){
                                    if(selectedMagnetData[i].action === "view" && selectedMagnetData[i].step === 0){
                                        log.views += 1
                                    }
                                    if(selectedMagnetData[i].action === "finish" && selectedMagnetData[i].step === selectedMagnet.steps.length -1){
                                        log.completions += 1
                                    }
                                    if(selectedMagnetData[i].action === "start"){
                                        log[`step${selectedMagnetData[i].step}_start`] += 1;
                                    }
                                    if(selectedMagnetData[i].action === "finish"){
                                        log[`step${selectedMagnetData[i].step}_finish`] += 1;
                                    }
                                }
                            });
                            if(selectedMagnetData[i].action ==="start"){
                                if(dataDays[j][`step${selectedMagnetData[i].step}_start`]){
                                    dataDays[j][`step${selectedMagnetData[i].step}_start`] += 1;
                                    
                                }else{
                                    dataDays[j][`step${selectedMagnetData[i].step}_start`] = 1;
                                }
                                if(totalData[0][`step${selectedMagnetData[i].step}_start`]){
                                    totalData[0][`step${selectedMagnetData[i].step}_start`] += 1;
                                }else{
                                    totalData[0][`step${selectedMagnetData[i].step}_start`] = 1;
                                }
                            }
                            if(selectedMagnetData[i].action ==="finish"){
                                if(dataDays[j][`step${selectedMagnetData[i].step}_finish`]){
                                    dataDays[j][`step${selectedMagnetData[i].step}_finish`] += 1;
                                }else{
                                    dataDays[j][`step${selectedMagnetData[i].step}_finish`] = 1;
                                }
                                if(totalData[0][`step${selectedMagnetData[i].step}_finish`]){
                                    totalData[0][`step${selectedMagnetData[i].step}_finish`] += 1;
                                }else{
                                    totalData[0][`step${selectedMagnetData[i].step}_finish`] = 1;
                                }
                            }
                            if(selectedMagnetData[i].action ==="pause"){
                                if(dataDays[j][`step${selectedMagnetData[i].step}_pause`]){
                                    let old = dataDays[j][`step${selectedMagnetData[i].step}_pause`];
                                    old[0] += 1;
                                    old[1] += selectedMagnetData[i].percentage;
                                    dataDays[j][`step${selectedMagnetData[i].step}_pause`] = old;
                                }else{
                                    dataDays[j][`step${selectedMagnetData[i].step}_pause`] = [1, selectedMagnetData[i].percentage];
                                }
                                if(totalData[0][`step${selectedMagnetData[i].step}_pause`]){
                                    let old = totalData[0][`step${selectedMagnetData[i].step}_pause`];
                                    old[0] += 1;
                                    old[1] += selectedMagnetData[i].percentage;
                                    totalData[0][`step${selectedMagnetData[i].step}_pause`] = old;
                                }else{
                                    totalData[0][`step${selectedMagnetData[i].step}_pause`] = [1, selectedMagnetData[i].percentage];
                                }
                            }
                        }
                    }
                }
            }
            let remove : number[] = []
            totalData.forEach((data: any)=>{
                data["completion_ratio"] = ((data.completions / data.views) * 100).toFixed(2);
                if(data.views === 0){
                    remove.push(totalData.indexOf(data));
                }
            });

            console.log(totalData);
            setDateData(dataDays);
            if((totalData.filter((data : any) => data.views > 0)).length > 0){
                setTotals(totalData.filter((data : any) => data.views > 0));
            }else{
                setTotals([totalData[0]]);
            }
            setKeys(Object.keys(totalData[0]));
            //console.log(totalData, dataDays);
        }
    }
    
    if(updateRun === 0){
        
        if(selectedMagnetData && selectedMagnetData.length !== 0){
            const date1 : Date = new Date(selectedMagnetData[0].date);
            const date2 : Date = new Date();
            editDateData(new Date(date1.getFullYear(), date1.getMonth(),date1.getDate()), new Date(date2.getFullYear(), date2.getMonth(),date2.getDate()));
            setUpdateRun(updateRun + 1);
        }
        if(selectedMagnetData && selectedMagnetData.length === 0){
            setUpdateRun(updateRun + 1);
        }
    }

    const CancelNameChange = () => {
        const sessionMagnet = sessionStorage.getItem("selectedMagnet");
        if(sessionMagnet){
            const parsedSelectedMagnet = JSON.parse(sessionMagnet);
            setSelectedMagnet({...selectedMagnet, form_name : parsedSelectedMagnet.form_name});
        }
    }
    

    return(
        <div className="mainFlexBox">
            <Button startIcon={<ArrowBackIcon />} color="secondary" style={{marginTop :"20px"}}
            onClick={()=>{navigate("/sequences"); sessionStorage.removeItem("selectedMagnet");}}>Back to all sequences</Button>
            <div className="innerNavigationBar">
                <div className="subFlexBox">
                    <div className="subFlexLeft">
                        {
                            (editName)
                            ? <div style={{display: "flex", alignItems: "center", justifyContent :"start"}}>
                                <TextField 
                                sx={{width : "30%"}}
                                value={selectedMagnet.form_name} 
                                onChange={(e)=>{setSelectedMagnet({...selectedMagnet, form_name : e.target.value as string})}}
                                />
                                <Button variant="contained" sx={{margin: "5px"}} 
                                onClick={()=>{editMagnetName(selectedMagnet._id); setEditName(false);}}>Save changes</Button>
                                <Button variant="outlined" color="error" sx={{margin: "5px"}} 
                                onClick={()=>{CancelNameChange(); setEditName(false);}}>Cancel</Button>
                            </div>
                            :<Typography variant="h6" style={{margin: "10px", display: "flex", justifyContent:"start", alignItems:"center"}} onClick={()=>{setEditName(true)}}>
                                {
                                    (loadDone)
                                    ?<span>{`${(selectedMagnet.form_name !== undefined && selectedMagnet.form_name !== null)? selectedMagnet.form_name : "(No name...)"}  `}</span>
                                    :<span>loading...</span>
                                } 
                                <Tooltip title="Edit lead magnet name"><EditIcon sx={{fontSize : "18px", margin: "10px", color :"#323377"}}/></Tooltip>
                             </Typography>
                        }
                    </div>
                    <div className="subFlexRight">
                        <Button variant="contained" style={{margin: "10px"}} color="secondary" startIcon={<VisibilityIcon />}
                        onClick={()=>{openPreview(selectedMagnet._id);}}>Preview & embed</Button>
                        <Button variant="contained"  style={{margin: "10px"}} 
                        onClick={()=>{navigate("/sequences/edit")}} startIcon={<EditIcon/>}>Edit sequence</Button>
                        <Button variant="outlined" color="error" style={{margin: "10px"}} startIcon={<DeleteForeverIcon />}
                        onClick={()=>{setDeletePop(true);}}>Delete</Button>
                    </div>
                </div>
            </div>
            {
                (loadDone && selectedMagnet.steps !== null && selectedMagnet.steps.length > 0)
                ?<div className="magnetResults">
                    <Typography variant="h5" style={{fontWeight :"600"}}>Keep Track of your results &#128640;</Typography><br/>
                    <Typography>Monitor your lead magnets' results in real time to stay up to date on what's working and what needs improving</Typography>
                    {
                        (selectedMagnetData && selectedMagnetData.length > 0)
                        ?<div style={{display :"flex", width : "70%", justifyContent : "flex-start", alignItems : "center", padding : "20px 0px 20px 0px"}}>
                            <ButtonGroup style={{margin: "10px"}} size="large">
                                <Button startIcon={<AnalyticsIcon />}
                                variant={showNewView? "contained": "outlined"} 
                                onClick={()=>{setShowNewView(true);}}>New View</Button>
                                <Button startIcon={<ViewAgendaIcon />}
                                variant={showNewView? "outlined": "contained"} 
                                onClick={()=>{setShowNewView(false);}}>Old view</Button>
                            </ButtonGroup>
                            <Fade in={!showNewView}>
                                <Alert severity="info">
                                    <AlertTitle><b>Info</b></AlertTitle>
                                    New view available with date based tracking from 11/6/2024 forwards
                                </Alert>
                            </Fade>
                        </div>
                        : null
                        
                    }
                    <Box style={{display :"flex", flexWrap : "wrap", width : "75%", padding : "10px 0px 10px 0px"}}>
                        {
                            (selectedMagnetData && selectedMagnetData.length !== 0 && dateData.length > 0 && showNewView)
                            ?<div style={{}}><br/>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker label="Start date:" sx={{margin : "0px 10px 0px 10px"}} onChange={(e)=>{
                                        if(e !== null){
                                            setSelectedDays({...selectedDays, date1 : (new Date(e.valueOf()))});
                                        }
                                        }}/>
                                    <DatePicker label="End date:" sx={{margin : "0px 10px 0px 10px"}} onChange={(e)=>{
                                        if(e !== null){
                                            setSelectedDays({...selectedDays, date2 : (new Date(e.valueOf()))});
                                        }
                                        }}/>
                                </LocalizationProvider>
                                <Button variant="contained" sx={{margin : "10px"}} disabled={(selectedDays.date1 === "") || (selectedDays.date2 === "")}
                                onClick={()=>{editDateData(selectedDays.date1, selectedDays.date2)}}>Show data from selected dates</Button><br/>
                                <LineChart dataset={dateData} grid={{vertical : false, horizontal : true}}  series={[
                                {dataKey : "views", label : "Page Views"},{dataKey : "completions", label : "Funnel completions"}
                                ]} height={200} width={tableWidth} xAxis={[{scaleType: "band", dataKey : "date"}]}/>
                                <DataGrid disableRowSelectionOnClick sx={{width : tableWidth}} autoHeight
                                columns={[
                                    {field : "source", width : tableWidth / 6}, 
                                    {field : "Page views", width : tableWidth / 6},
                                    {field : "Funnel Starts", width : tableWidth / 6},
                                    {field : "Start rate", width: tableWidth / 6}, 
                                    {field : "Funnel completions", width : tableWidth / 6}, 
                                    {field : "Completion rate", width : tableWidth / 6}]} 
                                rows={totals.map((data : any, idx : number)=>{
                                    return {
                                        id: idx, 
                                        source : data.source, 
                                        ["Page views"] : data.views,
                                        ["Funnel Starts"] : data.step0_start,
                                        ["Start rate"]: ((data.step0_start / data.views)*100).toFixed(2) + " %", 
                                        ["Funnel completions"] : data.completions, 
                                        ["Completion rate"] : `${data.completion_ratio} %`}
                                })}
                                />
                            </div>
                            :<div style={{display : "flex", width : "100%"}}>
                                <Paper elevation={5} 
                                style={{margin: "10px", padding: "20px 5px 20px 5px", flex: "10%", justifyContent : "center", alignItems :"center", display : "flex"}}>
                                    <div className="resultCardFlex">
                                        <div className="resultIcon">
                                            <VisibilityIcon style={{fontSize: "60px", color : "#323377"}}/>
                                        </div>
                                        <div className="resultText">
                                            <Typography style={{fontSize: "18px"}}>Page views:</Typography>
                                            <Typography variant="h4">
                                                {
                                                    ( selectedMagnet.steps.length > 0 && selectedMagnet.steps[0].starts !== undefined)
                                                    ?<>
                                                        {
                                                            (selectedMagnet.steps[0].action === "video")
                                                            ? <span>{selectedMagnet.steps[0].views}</span>
                                                            : <span>N/A</span>
                                                        }
                                                    </>
                                                    :<span>N/A</span>
                                                }
                                            </Typography>
                                        </div>
                                    </div>
                                </Paper>
                                <Paper elevation={5} 
                                style={{margin: "10px", padding: "20px 5px 20px 5px", flex: "10%", justifyContent : "center", alignItems :"center", display : "flex"}}>
                                    <div className="resultCardFlex">
                                        <div className="resultIcon">
                                            <CheckCircleIcon style={{fontSize: "60px", color : "#00D3A8"}}/>
                                        </div>
                                        <div className="resultText">
                                            <Typography style={{fontSize: "16px"}}>Funnel completions:</Typography>
                                            <Typography variant="h4">
                                                {
                                                    (selectedMagnet.steps[selectedMagnet.steps.length - 1].finish !== undefined)
                                                    ? <span>{selectedMagnet.steps[selectedMagnet.steps.length - 1].finish}</span>
                                                    : <span>N/A</span>
                                                }
                                            </Typography>
                                        </div>
                                    </div>
                                </Paper>
                                <Paper elevation={5} 
                                style={{margin: "10px", padding: "10px 5px 10px 5px", flex: "10%", justifyContent : "center", alignItems :"center", display : "flex"}}>
                                    <div className="resultCardFlex">
                                        <div className="resultText" style={{textAlign: "center",  justifyContent : "center", alignItems :"center", display : "flex", flexDirection :"column"}}>
                                            <div style={{flex:"100%"}}><Typography style={{fontSize: "16px"}}>Completion ratio:</Typography></div>
                                            {
                                                (selectedMagnet.steps[0].action === "video")
                                                ?<div style={{flex:"100%"}}><Gauge innerRadius={50} outerRadius={90} cornerRadius={2}
                                                value={((selectedMagnet.steps[selectedMagnet.steps.length - 1].finish / selectedMagnet.steps[0].views) * 100)} 
                                                width={200} height={100} startAngle={-90} endAngle={90} valueMin={0} valueMax={100} text={({value}) => `${value?.toFixed(2)}%`}/>
                                                </div>
                                                :<Typography variant="h4">N/A</Typography>
                                            }
                                        </div>
                                    </div>
                                </Paper>
                            </div>
                        }
                    </Box>
                 </div>
                :<div className="magnetResults">
                    <Typography variant="h5" style={{fontWeight :"600"}}>Keep Track of your results &#128640;</Typography><br/>
                    <Typography>Monitor your lead magnets' results in real time to stay up to date on what's working and what needs improving</Typography>
                    <Box style={{display :"flex", flexWrap : "wrap", width : "75%", padding : "10px 0px 10px 0px"}}>
                        <Paper elevation={5} 
                        style={{margin: "10px", padding: "20px 5px 20px 5px", flex: "10%", justifyContent : "center", alignItems :"center", display : "flex"}}>
                            <div className="resultCardFlex">
                                <div className="resultIcon">
                                    <VisibilityIcon style={{fontSize: "60px", color : "#323377"}}/>
                                </div>
                                <div className="resultText">
                                    <Typography style={{fontSize: "18px"}}>Page views:</Typography>
                                    <Typography variant="h4">
                                        N/A
                                    </Typography>
                                </div>
                            </div>
                        </Paper>
                        <Paper elevation={5} 
                        style={{margin: "10px", padding: "20px 5px 20px 5px", flex: "10%", justifyContent : "center", alignItems :"center", display : "flex"}}>
                            <div className="resultCardFlex">
                                <div className="resultIcon">
                                    <CheckCircleIcon style={{fontSize: "60px", color : "#00D3A8"}}/>
                                </div>
                                <div className="resultText">
                                    <Typography style={{fontSize: "16px"}}>Funnel completions:</Typography>
                                    <Typography variant="h4">N/A</Typography>
                                </div>
                            </div>
                        </Paper>
                        <Paper elevation={5} 
                        style={{margin: "10px", padding: "20px 5px 20px 5px", flex: "10%", justifyContent : "center", alignItems :"center", display : "flex"}}>
                            <div className="resultCardFlex">
                                <div className="resultIcon">
                                    <OndemandVideoIcon style={{fontSize: "60px", color : "#FFBA5B"}}/>
                                </div>
                                <div className="resultText">
                                    <Typography style={{fontSize: "16px"}}>Completion ratio:</Typography>
                                    <Typography variant="h4">
                                        N/A
                                    </Typography>
                                </div>
                            </div>
                        </Paper>
                    </Box>
                </div>
            }
            <div className="resultsAndSteps">
                <div className="results">
                    {
                        (loadDone)
                        ?<>
                        {
                            (selectedMagnet.steps !== null)
                            ?<>
                                {selectedMagnet.steps.map((magnet: Steps, idx: number) => {
                                    return  <Accordion key={idx} style={{boxShadow: "0px 0px 3px 1px grey"}}>
                                                <AccordionSummary expandIcon={<ExpandMore />}>
                                                    <Typography variant="h6" sx={{width : "50%", flexShrink : 0}}>
                                                        Step {idx + 1}: {magnet.action} 
                                                    </Typography>
                                                    {(showNewView)
                                                        ?<Typography sx={{width :"45%", textAlign : "right"}}>
                                                            completion ratio : {((totals[0][`step${idx}_finish`] / totals[0][`step${idx}_start`])*100).toFixed(2)} %
                                                         </Typography>
                                                        :null
                                                    }
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {
                                                        (magnet.action === "video")
                                                        ? <VideoResults magnet={magnet} idx={idx} totals={totals} showNewView={showNewView}/>
                                                        :<>
                                                            {
                                                                (magnet.action === "form")
                                                                ? <FormResulsts magnet={magnet} totals={totals} idx={idx} showNewView={showNewView}/>
                                                                :<>
                                                                    {
                                                                        (magnet.action === "end screen")
                                                                        ? <ThxResults magnet={magnet} totals={totals} idx={idx} showNewView={showNewView}/>
                                                                        : null
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </AccordionDetails>
                                            </Accordion>
                                })}
                            </>
                            :<div>
                                <Typography variant="h5">You haven't saved any steps to your lead magnet yet...&#128064;</Typography><br/>
                                <Typography>Your results will be displayed here after you've saved steps to your lead magnet</Typography>
                            </div>
                        }
                        </>
                        :<Typography>Results are loading....</Typography>
                    }
                </div>
                <div className="otherMagnetStuff">
                    <div className="leadList">
                        {
                            (loadDone && selectedMagnetLeads)
                            ?<>
                                {
                                    (selectedMagnetLeads.length > 0)
                                    ?<List 
                                    style={{padding: "15px", backgroundColor:"#323377", borderRadius : "5px"}}
                                    >
                                        <Typography variant="h5" style={{color:"white", textShadow:"0px 1px 2px black"}}>Generated leads:</Typography><br/>
                                        <Typography style={{color:"white", textShadow:"0px 1px 2px black"}}>Click on a lead to open more information <br/>{`(feature mentioned above will be added soon...)`}</Typography><br/>
                                        {selectedMagnetLeads.reverse().slice(0,5).map((lead : any, idx : number) => {
                                            return <ListItem key={idx} style={{boxShadow: "0px 0px 3px 1px grey", borderRadius :"5px", backgroundColor: "white", marginTop: "10px"}}>
                                                        <ListItemAvatar>
                                                            <Avatar style={{backgroundColor:"#00D3A8"}}>
                                                                <PersonIcon style={{color:"white"}} />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText 
                                                        primary={<React.Fragment><Typography variant="h6">{lead.firstName} {lead.lastName}</Typography></React.Fragment>}
                                                        secondary={
                                                            <React.Fragment>
                                                                <Typography style={{fontSize:"12px"}}><b>{lead.email}</b></Typography>
                                                                <Typography style={{fontSize:"12px"}}><b>Company:</b> {lead.company}</Typography>
                                                                <Typography style={{fontSize:"12px"}}><b>Became a lead:</b> {lead.created_at}</Typography>
                                                            </React.Fragment>
                                                        }
                                                        />
                                                   </ListItem>
                                        })}
                                     </List>
                                    :null
                                }
                            </>
                            : <Typography>This sequence doesn't collect leads...</Typography>
                        }
                    </div>
                </div>
            </div>
            <Modal open={deletePop} onClose={()=>{setDeletePop(false)}}>
                    <Box style={{
                        position : "absolute", 
                        top :"50%", left :"50%", 
                        backgroundColor : "white", 
                        padding : "20px", 
                        borderRadius : "5px",
                        transform : "translate(-50%, -50%)",
                        textAlign : "center",
                        }}>
                        <Container>
                             <Typography variant="h6" style={{margin: "20px 20px 20px 20px"}}>
                                You are about to delete this lead magnet:
                             </Typography>
                             <Typography variant="h4" style={{margin: "20px 20px 20px 20px", color : "#323377"}}>
                                <b>{selectedMagnet.form_name}</b>
                             </Typography>
                             <br/>
                             <Typography>Are you sure you want to continue?</Typography>
                             <Button variant="outlined" startIcon={<ArrowBackIcon />} color="error" 
                             onClick={()=>{setDeletePop(false)}} style={{margin: "20px"}}>No, return</Button>
                             <Button variant="contained" style={{margin: "20px"}} startIcon={<DeleteForeverIcon />} color="success"
                             onClick={()=>{deleteSequence(selectedMagnet._id, selectedMagnet.workspace_id)}}>Yes, delete</Button>                
                        </Container>
                    </Box>
            </Modal>
        </div>
    );
};

export default LeadMagnet;