import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React, { useContext } from "react";
import { Context } from "./Context";
import { useLocation } from "react-router-dom";

const MainNavigation = (props : any) : React.ReactElement => {
    const { navigate, setCurrentPage, currentPage } = useContext(Context);
    const location = useLocation();
    
    return(
        <div>
            <div>
              <List style={{color:"white"}}>
                {
                    props.pages.map((page : any, idx : number)=>{
                        return <ListItem key={idx} 
                        onClick={()=>{navigate(`${page.url}`); document.title =`CX Tailor - ${page.pageName}`; setCurrentPage({...currentPage, page_title : page.pageName, page_description : page.description});}}>
                          <ListItemButton style={{
                          backgroundColor : `${(location.pathname === page.url)?"#F3F3F3":"#6156A5"}`, 
                          color : `${(location.pathname === page.url)?"black":"white"}`,
                          borderRadius: "20px"
                        }}>
                            <ListItemIcon style={{color : `${(location.pathname === page.url)?"black":"white"}`}}>
                              {page.icon}
                            </ListItemIcon>
                            <ListItemText primary={page.pageName}/>
                          </ListItemButton>
                        </ListItem>
                    })
                }
              </List>
            </div>
        </div>
    );
};

export default MainNavigation;