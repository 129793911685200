import { AppBar, IconButton, ListItemIcon, Menu, MenuItem, Toolbar, Tooltip, Typography } from "@mui/material";
import React, { useContext } from "react";
import { Context } from "./Context";
import Avatar from '@mui/material/Avatar';
import LogoutIcon from '@mui/icons-material/Logout';

const Topbar = () : React.ReactElement => {
    const { currentPage, user, LogOut, pages } = useContext(Context);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    
    //For menu opens and closes....
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
      };

    return(
        <div>
            <AppBar position='relative'>
              <Toolbar className='MainToolBar'>
                <Typography variant='h5'>
                    {currentPage.page_title}<br/>
                    <span style={{fontSize : "16px"}}>{currentPage.page_description}</span>
                    </Typography>
                <Tooltip title={
                    <React.Fragment>
                        <Typography>Open user settings</Typography>
                    </React.Fragment>
                }>
                    <IconButton sx={{position : "absolute", right : "20px"}} onClick={handleClick}>
                        <Avatar sx={{backgroundColor :"#6156A5", padding:"5px"}}>{user.first_name[0]}{user.last_name[0]}</Avatar>
                    </IconButton>
                </Tooltip>
                <Menu 
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                >
                    <MenuItem onClick={()=>{LogOut();handleClose()}}>
                        <ListItemIcon>
                            <LogoutIcon/>
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                </Menu>
              </Toolbar>
            </AppBar>
        </div>
    );
};

export default Topbar;