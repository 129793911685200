import { Box, Button, Card, CardActions, CardContent, CardMedia, Container, Grid, IconButton, Modal, Skeleton, TextField, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../components/Context";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddIcon from '@mui/icons-material/Add';
import Brightness1Icon from '@mui/icons-material/Brightness1';

const Sequences : React.FC = () : React.ReactElement => {

    const {listOfLeadMangets, loadDone, setNewLeadMagnet, newLeadMagnet , navigate, openLeadMagnet, setSteps, allMagnetDatas, listOfLeads} = useContext(Context);
    const [popUp, setPopUp] = useState<boolean>(false);
    const [showText, setShowText] = useState<boolean>(false);
    const [cards, setCards] = useState<number>(4);


    const handleAddNew = () => {
        if(newLeadMagnet.form_name.length > 0){
            sessionStorage.removeItem("newSteps");
            sessionStorage.setItem("newLeadMagnet", JSON.stringify(newLeadMagnet));
            sessionStorage.removeItem("selectedMagnet");
            setSteps([]);
            navigate("/sequences/addNew");
        }
    }

    useEffect(()=>{
        if(window.innerWidth > 1680){
            setCards(3);
        }
    }, []);

    return(
        <div className="mainFlexBox">
            <Grid container spacing={0} style={{padding: "20px"}}>
                <Grid item xs={12} md={4} lg={cards} onMouseOver={()=>{setShowText(true)}} onMouseOut={()=>{setShowText(false)}} onClick={()=>{setPopUp(true)}}>
                    <Card className="addNewHover" sx={{
                        minHeight: 350, 
                        margin: "10px" ,
                        display : "flex", 
                        justifyContent:"center", 
                        alignItems: "center",
                        border: "dashed",
                        borderWidth : "5px",
                        borderColor: "#6156A5",
                        backgroundColor :"#F3F3F3",
                        transition:"0.5s"
                        }}>
                        <CardContent>
                            {
                                (showText)
                                ?<div style={{ width : "100%"}}>
                                    <Typography sx={{zIndex: "10", textAlign:"center", color:"#F3F3F3"}}>Add new lead magnet</Typography>
                                </div>
                                :<div style={{ width : "100%"}}>
                                <Typography sx={{zIndex: "10", textAlign:"center", color:"#7F76B8"}}>Add new lead magnet</Typography>
                                </div>
                            }
                            <div style={{width: "100%"}}>
                            <IconButton disableRipple sx={{width: "100%"}}>
                                <AddIcon sx={{fontSize : "60px", color: `${(showText)?"#F3F3F3":"#6156A5"}`}}/>
                            </IconButton>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                {
                    (loadDone)
                    ?<>
                        {
                            (listOfLeadMangets && allMagnetDatas)
                            ?<>
                                {
                                    listOfLeadMangets.map((magnet: any, idx : number) => {
                                        var magnetData : any;
                                        var CollectsLeads : any ={collectsLeads : false};
                                        if(allMagnetDatas){
                                            allMagnetDatas.forEach((data : any) => {
                                                if(data.id === magnet._id){magnetData = data}
                                            })
                                        }
                                        if(Object.keys(magnet.steps).length > 0){
                                            for(let i = 0; i < Object.keys(magnet.steps).length; i++){
                                                if(magnet.steps[i].action === "form"){
                                                    CollectsLeads.collectsLeads = true;
                                                    CollectsLeads.step = i;
                                                }
                                            }
                                        }
                                        var thumbnail : string = "/CX-Tailor-Logo-Slogan.png";
                                        var ImgHeight : number = 200;
                                        var bg : string = "#323377"
                                        if(magnet.steps[0].action === "video"){
                                            if(magnet.steps[0].video.poster !== undefined && magnet.steps[0].video.poster !== ""){
                                                thumbnail = magnet.steps[0].video.poster;
                                                ImgHeight = 200;
                                            };
                                        }
                                        
                                        return<Grid item xs={12} md={4} lg={cards} key={idx}>
                                                <Card elevation={5} className="leadmagnetCard" sx={{
                                                minHeight: 350, 
                                                margin: "10px",
                                                backgroundColor :"#F3F3F3",
                                                position: "relative"
                                                }}
                                                onClick={()=>{openLeadMagnet(magnet._id)}}
                                                >
                                                    <CardMedia
                                                    src={thumbnail}
                                                    component="img"
                                                    sx={{height : ImgHeight, backgroundColor : bg, margin: "10px", borderRadius:"10px", width: "95%"}}  
                                                    title={magnet.form_name}/> 
                                                    <CardContent style={{position: "relative", minHeight : 100}}>
                                                        <Typography sx={{fontSize : "15px"}}>{
                                                        (typeof magnet.form_name === "string")
                                                        ?<span>{magnet.form_name.substring(0,30)}{( Object.keys(magnet.form_name).length > 25)?"...":""}</span>
                                                        : "(Magnet not named)"
                                                        }</Typography>
                                                        <Button variant="contained" sx={{ 
                                                        position : "absolute", 
                                                        right: "10px", 
                                                        top: "10px",
                                                        fontSize: "14px",
                                                        textTransform : "capitalize",
                                                        borderRadius: "20px",
                                                        backgroundColor:"#6156A5"
                                                        }}>
                                                            Open
                                                        </Button>
                                                        <Grid container spacing={1} style={{position : "absolute", bottom : "10px", width : "95%"}}>
                                                            <Grid item xs={4}>
                                                                <Card sx={{
                                                                    padding: "5px 10px 5px 10px",
                                                                    backgroundColor : `
                                                                    ${(magnetData.steps14Days !== undefined && (magnetData.steps14Days[0].start / magnetData.steps14Days[0].view * 100) >= 20)
                                                                        ?"#28C76F"
                                                                        :(((magnetData.steps14Days[0].start / magnetData.steps14Days[0].view * 100)) >= 10)? "#F8D800" :"#EA5455"}`,
                                                                }}>
                                                                    <Typography sx={{fontSize: "10px"}}>Start rate</Typography>
                                                                    <Typography style={{fontSize:"13px", padding:"5px"}}>
                                                                        {
                                                                            (magnetData && magnetData.steps14Days[0].view)
                                                                            ?<>
                                                                                <span>{(magnetData.steps14Days[0].start / magnetData.steps14Days[0].view * 100).toFixed(2)}%</span>
                                                                                {
                                                                                    (((magnetData.steps14Days[0].start - magnetData.stepsComparison[0].start) / magnetData.stepsComparison[0].start  * 100) !== Infinity)
                                                                                    ?<span style={{fontSize : "9px", margin: "5px", backgroundColor:"white",color: "black", borderRadius:"10px", padding:"5px"}}>
                                                                                        {(((magnetData.steps14Days[0].start - magnetData.stepsComparison[0].start) / magnetData.stepsComparison[0].start  * 100) > 0)?"+":null}
                                                                                        {((magnetData.steps14Days[0].start - magnetData.stepsComparison[0].start) / magnetData.stepsComparison[0].start  * 100).toFixed(0)}%
                                                                                    </span>
                                                                                    :null
                                                                                }
                                                                            </>
                                                                            : <span>0 %</span>
                                                                        }
                                                                    </Typography>
                                                                </Card>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Card sx={{
                                                                    padding: "5px 10px 5px 10px",
                                                                    color:"black",
                                                                    backgroundColor : `
                                                                    ${(magnetData.conversion14Days >= 5)
                                                                        ?"#28C76F"
                                                                        :(magnetData.conversion14Days >= 2)? "#F8D800" :"#EA5455"}`,        
                                                                }}>
                                                                    <Typography sx={{fontSize: "10px"}}>Conversion Rate</Typography>
                                                                    <Typography style={{fontSize:"13px", padding:"5px"}}>
                                                                        {
                                                                            (magnetData && magnetData.conversion14Days)
                                                                            ?<><span>{magnetData.conversion14Days}%</span>
                                                                                {
                                                                                    ((((magnetData.conversion14Days - magnetData.conversionComparison) / magnetData.conversionComparison)*100) !== Infinity)
                                                                                    ?<span style={{fontSize : "9px", margin: "5px", backgroundColor:"white",color: "black", borderRadius:"10px", padding:"5px"}}>
                                                                                        {((((magnetData.conversion14Days - magnetData.conversionComparison) / magnetData.conversionComparison)*100) > 0)?"+":null}
                                                                                        {
                                                                                            (((magnetData.conversion14Days - magnetData.conversionComparison) / magnetData.conversionComparison)*100).toFixed(0)
                                                                                            
                                                                                        }%
                                                                                    </span>
                                                                                    : null
                                                                                }
                                                                            </>
                                                                            : 0
                                                                        }
                                                                    </Typography>
                                                                </Card>
                                                            </Grid>
                                                            {
                                                                (CollectsLeads.collectsLeads)
                                                                ?<Grid item xs={4}>
                                                                    <Card sx={{
                                                                        padding: "5px 10px 5px 10px"
                                                                    }}>
                                                                        <Typography sx={{fontSize: "10px"}}>Leads</Typography>
                                                                        <Typography style={{fontSize:"13px", padding:"5px"}}>
                                                                               {magnetData.steps14Days[CollectsLeads.step].finish}
                                                                               {
                                                                                ((((magnetData.steps14Days[CollectsLeads.step].finish - magnetData.stepsComparison[CollectsLeads.step].finish)/magnetData.stepsComparison[CollectsLeads.step].finish) * 100) !== Infinity)
                                                                                ?<span style={{fontSize : "9px", margin: "5px", backgroundColor:"white",color: "black", borderRadius:"10px", padding:"5px"}}>
                                                                                {
                                                                                    (((((magnetData.steps14Days[CollectsLeads.step].finish - magnetData.stepsComparison[CollectsLeads.step].finish)/magnetData.stepsComparison[CollectsLeads.step].finish) * 100) > 0))
                                                                                    ? "+"
                                                                                    : null
                                                                                }
                                                                                {
                                                                                 ((((magnetData.steps14Days[CollectsLeads.step].finish - magnetData.stepsComparison[CollectsLeads.step].finish)/magnetData.stepsComparison[CollectsLeads.step].finish) * 100).toFixed(0) !== "NaN")
                                                                                 ?<>{(((magnetData.steps14Days[CollectsLeads.step].finish - magnetData.stepsComparison[CollectsLeads.step].finish)/magnetData.stepsComparison[CollectsLeads.step].finish) * 100).toFixed(0)}</>
                                                                                 :"0%"
                                                                                }</span>
                                                                                :null
                                                                               }
                                                                        </Typography>
                                                                    </Card>
                                                                </Grid>
                                                                :null
                                                            }    
                                                        </Grid>
                                                    </CardContent>
                                                    <Typography sx={{position:"absolute", top:"15px", right: "17px"}}>
                                                        <Tooltip sx={{backgroundColor:"white"}} placement="left" slotProps={{popper: {modifiers: [{name: 'offset',options: {offset: [0, -14],},},],},}}title={
                                                            <React.Fragment >
                                                                <Typography>
                                                                    {
                                                                        ((magnetData.steps14Days[0].view > 0))
                                                                        ?"Active"
                                                                        :"Inactive - No data from last 14 days"
                                                                    }
                                                                </Typography>
                                                            </React.Fragment>
                                                        }>
                                                            <Brightness1Icon className={`${((magnetData.steps14Days[0].view > 0))?"IconPulseSlow":"IconPulseFast"}`} sx={{
                                                                color: `${(magnetData.steps14Days[0].view > 0)?"#81FBB8" :"#EA5455"}`,
                                                                backgroundColor: `${(magnetData.steps14Days[0].view > 0)?"#28C76F" :"#EA5455"}`,
                                                                
                                                                borderRadius: "100px",
                                                                fontSize:"15px",
                                                            }}/>
                                                        </Tooltip>
                                                    </Typography>
                                                </Card>
                                              </Grid>
                                    })
                                }
                            </>
                            :<>
                            {
                                [...Array(7)].map((idx : number) =>{
                                    return<Grid item xs={12} md={4} lg={cards} key={idx}>
                                            <Skeleton variant="rounded" sx={{width : "95%", height: "350px", margin: "10px"}}/>
                                          </Grid>
                                })
                            }
                            </>
                        }
                    </>
                    :<>
                    {
                        [...Array(5)].map((idx : number) =>{
                            return<Grid item xs={4} key={idx}>
                                        <Skeleton variant="rounded" sx={{width : "100%"}}/>
                                  </Grid>
                        })
                    }
                    </>
                }
            </Grid>
            <Modal open={popUp} onClose={()=>{setPopUp(false)}}>
                    <Box style={{
                        position : "absolute", 
                        top :"50%", left :"50%", 
                        backgroundColor : "white", 
                        padding : "20px", 
                        borderRadius : "5px",
                        transform : "translate(-50%, -50%)",
                        textAlign : "center",
                        }}>
                        <Container>
                            <Typography variant="h4">Add a new Lead magnet:</Typography>
                            <br/>
                            <Typography>Add a name to your lead magnet:</Typography>
                            <TextField name="Lead Magnet name" placeholder="Name your lead magnet..."
                            style={{margin : "20px"}} 
                            onChange={(e)=>{setNewLeadMagnet({...newLeadMagnet, form_name : e.target.value as string})}}/><br/>
                            <Button onClick={()=>{setPopUp(false);}} style={{margin : "5px"}} variant="outlined" color="secondary">Cancel</Button>
                            <Button variant="contained" onClick={()=>{handleAddNew()}}>Continue</Button>
                        </Container>
                    </Box>
            </Modal>
        </div>
    )
};

export default Sequences;