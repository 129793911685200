import React, { useContext } from "react";
import NewSequencePreview from "../components/NewSequencePreview";
import { Button, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import { Context } from "../components/Context";
import EmbedSequence from "../components/embed";

const PreviewSequence : React.FC = () : React.ReactElement => {
    const {navigate, saveNewLeadMagnet, setSteps, setAccord, openLeadMagnet, selectedMagnet} = useContext(Context);

    return(
        <div className="mainFlexBox">
            <div className="innerNavigationBar">
                <div className="subFlexBox">
                    <div className="subFlexLeft">
                        <Button color="secondary" startIcon={<ArrowBackIcon />} onClick={()=>{openLeadMagnet(selectedMagnet._id)}}>Open lead magnet info</Button>
                    </div>
                    <div className="subFlexRight">
                        <Button variant="contained" startIcon={<SaveIcon />} onClick={()=>{navigate("/sequences"); setAccord(0); setSteps([]); sessionStorage.removeItem("newSteps");}}>Save and exit</Button>
                    </div>
                </div>
                <Typography>Preview your sequence</Typography>
            </div>
            <div className="previewFlex">
                <div className="previewWindow">
                    <NewSequencePreview />
                </div>
                <div className="embedWindow">
                    <EmbedSequence />
                </div>

            </div>
        </div>
    );
}

export default PreviewSequence;