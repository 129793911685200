import { Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Tooltip, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Context } from "../components/Context";

const LeadList : React.FC = () : React.ReactElement => {
    const { listOfLeads, loadDone } = useContext(Context);
    const [page, setPage] = useState<number>(0);
    const [rows, setRows] = useState<number>(10);

    const handlePageChange = (event : unknown, newPage : number) => {
        setPage(newPage);
    }

    const handleRowsPerPageChange = (event : React.ChangeEvent<HTMLInputElement>)=>{
        setRows(parseInt(event.target.value));
        setPage(0);
    };


    return(
        <div className="mainFlexBox">
            <div className="LeadListMain" style={{width: "100%", padding : "20px"}}>
                <Typography>Filttereitä tähän?</Typography>
                <Paper elevation={5}>
                    <Table aria-label="LeadList" sx={{width: "90%", marginTop : "20px"}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">Lead Name</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h6">Company</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h6">
                                        Lead magnet
                                        <span>
                                            <Tooltip title={<React.Fragment>Which of your lead magnets converted this lead </React.Fragment>}>
                                                <HelpOutlineIcon style={{fontSize:"14px", padding : "5px"}} />
                                            </Tooltip>
                                        </span>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h6">Became a lead</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                (loadDone && listOfLeads)
                                ?<>
                                    {
                                        (listOfLeads.length !== undefined && listOfLeads.length > 0)
                                        ?<>
                                            {
                                                listOfLeads.slice((page * rows), page * rows + rows).map((lead : any, idx : number)=>{
                                                    return<TableRow hover key={idx}>
                                                            <TableCell sx={{width: "40%"}}>{`${lead.firstName} ${lead.lastName}`}</TableCell>
                                                            <TableCell sx={{width: "700%"}}>{lead.company}</TableCell>
                                                            <TableCell sx={{width: "50%"}}>{lead.form_id}</TableCell>
                                                            <TableCell sx={{width: "100%"}}>{lead.created_at}</TableCell>
                                                            </TableRow>
                                                })
                                            }
                                        </>
                                        :<TableRow>
                                            <TableCell colSpan={5}>
                                                <div style={{minHeight : 200 ,display:"flex", justifyContent : "center", alignItems : "center"}}>
                                                    <Paper sx={{padding: "20px"}} elevation={5}>
                                                        <Typography variant="h5">No leads collected yet</Typography>
                                                    </Paper>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    }
                                </>
                                :null
                            }
                        </TableBody>
                    </Table>
                    <TablePagination
                    rowsPerPageOptions={[10,15,25,50]}
                    page={page}
                    rowsPerPage={rows}
                    count={
                        (loadDone && listOfLeads)
                        ? (listOfLeads.length !== undefined && listOfLeads.length > 0) ? listOfLeads.length : 0
                        : 0
                    }
                    component="div"
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange} 
                    />
                </Paper>
            </div>
        </div>
    );
};

export default LeadList;