import { Alert, AlertTitle, Box, Button, Collapse, Grid, MenuItem, Modal, Paper, Select, Skeleton, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import "../styles/settings.css";
import { Context, User } from "../components/Context";
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Brightness1Icon from '@mui/icons-material/Brightness1';

const Settings : React.FC = () : React.ReactElement => {
    const { workspace, workspaceUsers, deleteUser, user, inviteUser, invitedUsers, inviteSent} = useContext(Context);
    const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<User>();
    const [inviteOpen, setInviteOpen] = useState<boolean>(false);
    const [inviteRights, setInviteRights] = useState<string>("");
    const [inviteEmail, setInviteEmail] = useState<string>("");
    const [inviteNotification, setInviteNotification] = useState<boolean>(false);

    const handleInvite = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        inviteUser(inviteEmail, inviteRights, workspace._id, workspace.workspace_name, user.email);
        setInviteNotification(true);
        setInviteOpen(false);
        setInviteRights("");
        setInviteEmail("");
    }

    return(
        <div className="mainFlexBox">
            <Paper className="settingsBox" elevation={2}>
                <div className="sectionHeader">
                    <Typography><b>Account Info</b></Typography>
                </div>
                <div className="sectionContent">
                    <Typography>Account name</Typography>
                    {
                        (workspace && workspace.workspace_name !== undefined)
                        ?<TextField value={workspace.workspace_name} disabled className="disabledInput"/>
                        :<Skeleton variant="rounded" sx={{width : "95%", height: "50px", margin: "5px"}}/>
                    }
                    <Typography sx={{marginTop:"20px"}}>Account level</Typography>
                    {
                        (workspace && workspace.level !== undefined)
                        ?<Typography><b>{workspace.level}</b></Typography>
                        :<Skeleton variant="rounded" sx={{width : "95%", height: "50px", margin: "5px"}}/>
                    }
                </div>
            </Paper>
            <Paper className="settingsBox">
                <div className="sectionHeader">
                    <Typography><b>Users</b></Typography>
                    <Button sx={{position:"absolute", top: "10px", right: "15px", backgroundColor:"#6156A5", textTransform:"capitalize"}} variant="contained" startIcon={<PersonAddIcon />}
                     disabled={user.user_rights !== "admin" && user.user_rights !== "owner"}
                     onClick={()=>{setInviteOpen(true)}}
                    >
                        Invite new user
                    </Button>
                </div>
                <div className="sectionContent">
                    
                        <Collapse in={inviteOpen}>
                            <Paper className="invitationBox" elevation={2}>
                                <Typography sx={{marginTop:"10px", marginBottom: "20px"}}><b>Invite new user to your account</b></Typography>
                                <form onSubmit={handleInvite}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Typography>Email:</Typography>
                                        <TextField fullWidth type="email" required placeholder="Email" value={inviteEmail} 
                                        onChange={(e)=>{setInviteEmail(e.target.value as string)}}/>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Typography>Access:</Typography>
                                        <Select value={inviteRights} required onChange={(e)=>{setInviteRights(e.target.value as string)}} fullWidth>
                                            <MenuItem value={"user"}>User</MenuItem>
                                            <MenuItem value={"admin"}>Admin</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} md={4} sx={{display:"flex", justifyContent:"start", alignItems:"end", gap :"5px"}}>
                                        <Button variant="outlined" color="error" size="large" sx={{textTransform:"capitalize"}} 
                                        onClick={()=>{setInviteOpen(false); setInviteEmail(""); setInviteRights("");}}>
                                            Cancel
                                        </Button>
                                        <Button variant="contained" size="large" startIcon={<PersonAddIcon />} sx={{backgroundColor:"#6156A5",  textTransform:"capitalize"}}
                                        type="submit"
                                        >
                                        Send invite
                                        </Button>
                                    </Grid>
                                </Grid>
                                </form>
                            </Paper>
                        </Collapse>
                    {
                        (workspaceUsers)
                        ?<Table aria-label="List of users" component={Paper} elevation={2}>
                            <TableHead sx={{backgroundColor: "#F3F3F3"}}>
                                <TableRow>
                                    <TableCell><Typography>Email</Typography></TableCell>
                                    <TableCell><Typography>Name</Typography></TableCell>
                                    <TableCell><Typography>Access</Typography></TableCell>
                                    <TableCell><Typography></Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    (workspaceUsers.length > 0)
                                    ?<>
                                        {
                                            workspaceUsers.map((workspaceUser:any, idx: number) => {
                                                return<TableRow key={idx} style={{position:"relative"}}>
                                                        <TableCell width={"30%"}><Typography>{workspaceUser.email}</Typography></TableCell>
                                                        <TableCell width={"30%"}><Typography>{`${workspaceUser.first_name} ${workspaceUser.last_name}`}</Typography></TableCell>
                                                        <TableCell width={"30%"}><Typography>{workspaceUser.user_rights}</Typography></TableCell>
                                                        <TableCell width={"10%"}>
                                                            {
                                                                (workspaceUser.user_rights === "owner")
                                                                ?<Button variant="outlined" color="error" sx={{textTransform:"capitalize"}} startIcon={<PersonRemoveIcon/>} disabled>
                                                                    Remove
                                                                </Button>
                                                                :<Button variant="outlined" color="error" startIcon={<PersonRemoveIcon/>} sx={{textTransform:"capitalize"}} 
                                                                disabled={user.user_rights !== "admin" && user.user_rights !== "owner"}
                                                                onClick={()=>{setDeleteConfirm(true); setSelectedUser(workspaceUser);}}
                                                                >
                                                                    Remove
                                                                </Button>
                                                            }
                                                        </TableCell>
                                                      </TableRow>
                                            })
                                        }
                                    </>
                                    :null
                                }
                                {
                                    (invitedUsers && invitedUsers.length > 0)
                                    ?<>
                                        {
                                            invitedUsers.map((invited: any, idx: any)=> {
                                                return <TableRow key={idx} style={{position:"relative"}}>
                                                            <TableCell width={"30%"}><Typography>{invited.email}</Typography></TableCell>
                                                            <TableCell width={"30%"}><Typography></Typography></TableCell>
                                                            <TableCell width={"30%"}><Typography>{invited.user_rights}</Typography></TableCell>
                                                            <TableCell width={"10%"}>
                                                                <Brightness1Icon sx={{fontSize: "10px", borderRadius : "100%",color:"#ec6c01", backgroundColor:"#ec6c01", marginRight:"10px"}} 
                                                                className="IconPulseSlow"/>
                                                                <b style={{color:"#ec6c01"}}>Invite pending</b>
                                                            </TableCell>
                                                        </TableRow>
                                            })
                                        }
                                    </>
                                    :null
                                }
                            </TableBody>
                        </Table>
                        :<p>Loading</p>
                    }
                </div>
            </Paper>
            <Modal open={deleteConfirm} onClose={()=>{setDeleteConfirm(false)}}>
                    <Box style={{
                        position : "absolute", 
                        top :"50%", left :"50%", 
                        backgroundColor : "white", 
                        padding : "40px", 
                        borderRadius : "5px",
                        transform : "translate(-50%, -50%)",
                        }}>
                        <Typography variant="h5">You're about to remove a user</Typography>
                        <div style={{marginTop: "20px", marginBottom: "20px"}}>
                            <Typography><b>User:</b> {`${selectedUser?.first_name} ${selectedUser?.last_name}`}</Typography>
                            <Typography><b>Email:</b> {selectedUser?.email}</Typography>
                        </div>
                        <div style={{marginTop: "20px", marginBottom: "20px"}}>
                            <Typography><b>This action is irreversible.</b> Are you sure you want to continue?</Typography>
                        </div>
                        <Button onClick={()=>{setDeleteConfirm(false);}} sx={{margin:"10px"}} variant="outlined" color="secondary">Cancel</Button>
                        <Button variant="contained" color="error" sx={{margin:"10px"}} startIcon={<PersonRemoveIcon/>} onClick={()=>{deleteUser(selectedUser?._id, selectedUser?.workspace_id);}}>Remove user</Button>
                       
                    </Box>
            </Modal>
            <Snackbar open={inviteNotification} autoHideDuration={6000} onClose={()=>{setInviteNotification(false)}}>
                {
                    (inviteSent && inviteSent !== undefined)
                    ?<Alert severity={inviteSent.severity} variant="filled" sx={{width : "100%"}}>
                        <AlertTitle sx={{textTransform:"capitalize"}}>{inviteSent!.severity}</AlertTitle>
                        {inviteSent.message}
                    </Alert>
                    :<Typography></Typography>
                }
            </Snackbar>
        </div>
    );
};

export default Settings;