import React, { useContext, useEffect, useState } from "react";
import { ContactForm, Context, TextStyle, textScreenStyle, ctaStyle, InputStyle } from "./Context";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, ButtonGroup, Container, InputAdornment, InputLabel, OutlinedInput, Tab, Tabs, TextField, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SegmentIcon from '@mui/icons-material/Segment';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';

const FormSelection = (props : any) : React.ReactElement => {
    const {steps, setSteps} = useContext(Context);
    const [expand, setExpand] = useState<boolean>(true);
    const [tabValue, setTabvalue] = useState<number>(0);
    const [form, setForm] = useState<ContactForm>({
            header : "",
            description: "",
            firstNameLabel : "",
            lastNameLabel : "",
            companyLabel : "",
            emailLabel : "",
            emailResults : "",
            ctaText : ""
    });
    const [textScreenStyle, setTextScreenStyle] = useState<textScreenStyle>({textAlign : "left", padding : "", backgroundColor : ""});
    const [h1Style, setH1style] = useState<TextStyle>({fontSize : "", color : ""});
    const [pStyle, setPStyle] = useState<TextStyle>({fontSize : "", color : ""});
    const [labelStyle, setLabelStyle] = useState<TextStyle>({fontSize : "", color : ""});
    const [inputStyle , setInputStyle] = useState<InputStyle>({width : "", padding : "", fontSize : "", borderRadius : ""});
    const [ctaStyle, setCtaStyle] = useState<ctaStyle>({fontSize : "", color: "", backgroundColor : "", padding : "", borderRadius : ""});
    

    const updateValues = () => {
        if(steps[props.idx].form){
            setForm(steps[props.idx].form);
            if(steps[props.idx].form.textScreenStyle){
                setTextScreenStyle(steps[props.idx].form.textScreenStyle)
            }
            if(steps[props.idx].form.h1Style){
                setH1style(steps[props.idx].form.h1Style)
            }
            if(steps[props.idx].form.pStyle){
                setPStyle(steps[props.idx].form.pStyle)
            }
            if(steps[props.idx].form.labelStyle){
                setLabelStyle(steps[props.idx].form.labelStyle)
            }
            if(steps[props.idx].form.inputStyle){
                setInputStyle(steps[props.idx].form.inputStyle)
            }
            if(steps[props.idx].form.ctaStyle){
                setCtaStyle(steps[props.idx].form.ctaStyle)
            }
        }
    }

    const handleStyleChange = () => {
        setForm({...form, textScreenStyle : textScreenStyle, h1Style : h1Style, pStyle : pStyle, labelStyle : labelStyle, inputStyle : inputStyle, ctaStyle : ctaStyle});
    }

    const saveStep = () => {
        handleStyleChange();
        steps[props.idx].form = form;
        setExpand(false);
    }

    const removeStep = () => {
        setSteps(steps.filter((step : any) => step !== steps[props.idx]));
        props.setAccord(props.accord - 1);
        props.setFormUsed(false);
    }

    useEffect(()=>{
        updateValues();
    }, []);

    return(
        <Accordion key={props.idx} expanded={expand} style={{boxShadow: "0px 0px 3px 1px grey"}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={()=>{setExpand(!expand)}}>
                <Typography sx={{width : "15%", flexShrink : 0}}>Step {props.idx + 1}: {steps[props.idx].action}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div className="StepSelectionFlexBox">
                    <div className="StepSelection">
                        <Box sx={{borderBottom : 1, borderColor : "divider", marginBottom : "20px"}}>
                            <Tabs value={ tabValue }>
                                <Tab icon={<SegmentIcon />} iconPosition="start" label="Content" onClick={()=>{setTabvalue(0); handleStyleChange();}} />
                                <Tab icon={<InvertColorsIcon />} iconPosition="start" label="Style" onClick={()=>{setTabvalue(1);}} />
                            </Tabs>
                        </Box>
                        {
                            (tabValue === 0)
                            ?<div>
                                <InputLabel id="header-label">Header:</InputLabel>
                                <TextField id="header" placeholder="Header here..." variant="outlined" size="small" fullWidth
                                value={form.header} 
                                onChange={(e)=>{setForm({...form, header : e.target.value as string})}}
                                />
                                <br/><br/>
                                <InputLabel id="description-label">Description</InputLabel>
                                <TextField id="description" placeholder="Engaging description here..." variant="outlined" size="small" multiline rows={3} fullWidth
                                value={form.description}
                                onChange={(e)=>{setForm({...form, description : e.target.value as string})}}
                                />
                                <br/><br/>
                                <div className="StepDetailsFlex">
                                    <div>
                                        <InputLabel id="firstName-label">First name field</InputLabel>
                                        <TextField id="firstName" placeholder="Fist name label..." size="small" variant="outlined" 
                                        value={form.firstNameLabel}
                                        onChange={(e)=>{setForm({...form, firstNameLabel : e.target.value as string})}}
                                        />
                                    </div>
                                    <div>
                                        <InputLabel id="LastName-label">Last name field</InputLabel>
                                        <TextField id="LastName" placeholder="Last name label..." size="small" variant="outlined"
                                        value={form.lastNameLabel}
                                        onChange={(e)=>{setForm({...form, lastNameLabel : e.target.value as string})}}
                                        />
                                    </div>
                                </div><br/>
                                <div className="StepDetailsFlex">
                                    <div>
                                        <InputLabel id="company-label">Company field</InputLabel>
                                        <TextField id="company" placeholder="company label..." size="small" variant="outlined"
                                        value={form.companyLabel}
                                        onChange={(e)=>{setForm({...form, companyLabel : e.target.value as string})}}
                                        />
                                    </div>
                                    <div>
                                        <InputLabel id="email-label">Email field</InputLabel>
                                        <TextField id="email" placeholder="email label..." size="small" variant="outlined"
                                        value={form.emailLabel}
                                        onChange={(e)=>{setForm({...form, emailLabel : e.target.value as string})}}
                                        />
                                    </div>
                                </div>
                                <br/>
                                <InputLabel id="cta-label">CTA button text</InputLabel>
                                <TextField id="cta" placeholder="Engaging CTA." variant="outlined" size="small" fullWidth
                                value={form.ctaText}
                                onChange={(e)=>{setForm({...form, ctaText : e.target.value as string})}}
                                />
                                <br/><br/>
                                <InputLabel id="cta-label">Who should recieve email notificaitons from new form submissions?</InputLabel>
                                <TextField id="cta" placeholder="Your email address..." variant="outlined" size="small" fullWidth
                                value={form.emailResults}
                                onChange={(e)=>{setForm({...form, emailResults : e.target.value as string})}}
                                />
                                <br/><br/>
                                <Button variant="outlined" color="secondary" onClick={saveStep} style={{margin : "0px 10px 0px 0px"}}><b>Save form step</b></Button> 
                                <Button variant="contained" style={{margin : "0px 0px 0px 10px"}} color="error" onClick={removeStep}>Remove this step</Button>
                            </div>
                            : null
                        }
                        {
                            (tabValue === 1)
                            ?<div>
                                <div className="StepDetailsFlex" style={{marginBottom : "20px"}}>
                                <div style={{width:"30%"}}>
                                        <InputLabel htmlFor="video-width">Text align:</InputLabel>
                                        <ButtonGroup size="large">
                                            {
                                                (textScreenStyle.textAlign === "left")
                                                ?<Button onClick={()=>{}} variant="contained">
                                                    <FormatAlignLeftIcon />
                                                </Button>
                                                :<Button onClick={()=>{setTextScreenStyle({...textScreenStyle, textAlign : "left"})}}>
                                                    <FormatAlignLeftIcon />
                                                </Button>
                                            }
                                            {
                                                (textScreenStyle.textAlign === "center")
                                                ?<Button onClick={()=>{}} variant="contained">
                                                    <FormatAlignCenterIcon />
                                                </Button>
                                                :<Button onClick={()=>{setTextScreenStyle({...textScreenStyle, textAlign : "center"})}}>
                                                    <FormatAlignCenterIcon />
                                                </Button>
                                            }
                                            {
                                                (textScreenStyle.textAlign === "right")
                                                ?<Button onClick={()=>{}} variant="contained">
                                                    <FormatAlignRightIcon />
                                                </Button>
                                                :<Button onClick={()=>{setTextScreenStyle({...textScreenStyle, textAlign : "right"})}}>
                                                    <FormatAlignRightIcon />
                                                </Button>
                                            }
                                        </ButtonGroup>
                                    </div>
                                    <div style={{width:"30%"}}>
                                        <InputLabel htmlFor="padding">Text screen padding: </InputLabel>
                                        <OutlinedInput id="padding" endAdornment={<InputAdornment position="end">px</InputAdornment>} type="number"
                                        value={textScreenStyle.padding} 
                                        onChange={(e)=>{setTextScreenStyle({...textScreenStyle, padding : e.target.value as string})}} placeholder="20" />
                                    </div>
                                    <div style={{width:"30%"}}>
                                        <InputLabel htmlFor="bg-color">Background:</InputLabel>
                                        <input type="color" id="bg-color" value={textScreenStyle.backgroundColor} style={{width : "50%", height : "50%"}}
                                        onChange={(e)=>{setTextScreenStyle({...textScreenStyle, backgroundColor : e.target.value as string})}}/>
                                    </div>
                                </div>
                                <div className="seperator">Text styling</div>
                                <div className="StepDetailsFlex" style={{marginBottom : "20px"}}>
                                    <div style={{width:"30%"}}>
                                        <InputLabel htmlFor="h1Size">Title font size:</InputLabel>
                                        <OutlinedInput id="h1Size" endAdornment={<InputAdornment position="end">px</InputAdornment>} placeholder="30" type="number"
                                        value={h1Style.fontSize} onChange={(e)=>{setH1style({...h1Style, fontSize : e.target.value as string})}}
                                        />
                                    </div>
                                    <div style={{width:"30%"}}>
                                        <InputLabel htmlFor="pSize">p -font size:</InputLabel>
                                        <OutlinedInput id="pSize" endAdornment={<InputAdornment position="end">px</InputAdornment>} placeholder="18" type="number"
                                        value={pStyle.fontSize} onChange={(e)=>{setPStyle({...pStyle, fontSize : e.target.value as string})}}
                                        />
                                    </div>
                                    <div style={{width:"15%"}}>
                                        <InputLabel htmlFor="title-color">Title color:</InputLabel>
                                        <input type="color" id="title-color" value={h1Style.color} style={{width : "100%", height : "50%"}}
                                        onChange={(e)=>{setH1style({...h1Style, color : e.target.value as string})}}/>
                                    </div>
                                    <div style={{width:"15%"}}>
                                        <InputLabel htmlFor="p-color">p -color:</InputLabel>
                                        <input type="color" id="p-color" value={pStyle.color} style={{width : "100%", height : "50%"}}
                                        onChange={(e)=>{setPStyle({...pStyle, color : e.target.value as string})}}/>
                                    </div>
                                </div>
                                <div className="seperator">Input label styling</div>
                                <div className="StepDetailsFlex" style={{marginBottom : "20px"}}>
                                    <div style={{width:"30%"}}>
                                        <InputLabel htmlFor="label-Size">label font size:</InputLabel>
                                        <OutlinedInput id="label-Size" endAdornment={<InputAdornment position="end">px</InputAdornment>} placeholder="18" type="number"
                                        value={labelStyle.fontSize} onChange={(e)=>{setLabelStyle({...labelStyle, fontSize : e.target.value as string});}}
                                        />
                                    </div>
                                    <div style={{width:"30%"}}>
                                        <InputLabel htmlFor="label-color">label color:</InputLabel>
                                        <input type="color" id="label-color" value={labelStyle.color} style={{width : "50%", height : "50%"}}
                                        onChange={(e)=>{setLabelStyle({...labelStyle, color : e.target.value as string});}}/>
                                    </div>
                                    <div style={{width:"30%"}}></div>
                                </div>
                                <div className="seperator">Input field styling</div>
                                <div className="StepDetailsFlex" style={{marginBottom : "20px"}}>
                                    <div style={{width:"20%"}}>
                                        <InputLabel htmlFor="input-Size">Input font size:</InputLabel>
                                        <OutlinedInput id="input-Size" endAdornment={<InputAdornment position="end">px</InputAdornment>} placeholder="16" type="number"
                                        value={inputStyle.fontSize} onChange={(e)=>{setInputStyle({...inputStyle, fontSize : e.target.value as string});}}
                                        />
                                    </div>
                                    <div style={{width:"20%"}}>
                                        <InputLabel htmlFor="input-width">Input width:</InputLabel>
                                        <OutlinedInput id="input-width" endAdornment={<InputAdornment position="end">%</InputAdornment>} placeholder="50" type="number"
                                        value={inputStyle.width} onChange={(e)=>{setInputStyle({...inputStyle, width : e.target.value as string});}}
                                        />
                                    </div>
                                    <div style={{width:"20%"}}>
                                        <InputLabel htmlFor="input-padding">Input padding:</InputLabel>
                                        <OutlinedInput id="input-padding" endAdornment={<InputAdornment position="end">px</InputAdornment>} placeholder="10" type="number"
                                        value={inputStyle.padding} onChange={(e)=>{setInputStyle({...inputStyle, padding : e.target.value as string});}}
                                        />
                                    </div>
                                    <div style={{width:"20%"}}>
                                        <InputLabel htmlFor="input-padding">border radius:</InputLabel>
                                        <OutlinedInput id="input-padding" endAdornment={<InputAdornment position="end">px</InputAdornment>} placeholder="10" type="number"
                                        value={inputStyle.borderRadius} onChange={(e)=>{setInputStyle({...inputStyle, borderRadius : e.target.value as string});}}
                                        />
                                    </div>
                                </div>
                                <div className="seperator">CTA styling</div>
                                <div className="StepDetailsFlex" style={{marginBottom : "20px"}}>
                                    <div style={{width:"18%"}}>
                                        <InputLabel htmlFor="cta-Size">Font size:</InputLabel>
                                        <OutlinedInput id="cta-Size" endAdornment={<InputAdornment position="end">px</InputAdornment>} placeholder="16" type="number"
                                        value={ctaStyle.fontSize} onChange={(e)=>{setCtaStyle({...ctaStyle, fontSize : e.target.value as string});}}
                                        />
                                    </div>
                                    <div style={{width:"18%"}}>
                                        <InputLabel htmlFor="cta-padding">Padding:</InputLabel>
                                        <OutlinedInput id="cta-padding" endAdornment={<InputAdornment position="end">px</InputAdornment>} placeholder="10" type="number"
                                        value={ctaStyle.padding} onChange={(e)=>{setCtaStyle({...ctaStyle, padding : e.target.value as string});}}
                                        />
                                    </div>
                                    <div style={{width:"18%"}}>
                                        <InputLabel htmlFor="cta-Size">Border radius:</InputLabel>
                                        <OutlinedInput id="cta-Size" endAdornment={<InputAdornment position="end">px</InputAdornment>} placeholder="10" type="number"
                                        value={ctaStyle.borderRadius} onChange={(e)=>{setCtaStyle({...ctaStyle, borderRadius : e.target.value as string});}}
                                        />
                                    </div>
                                    <div style={{width:"15%"}}>
                                        <InputLabel htmlFor="cta-color">Font color:</InputLabel>
                                        <input type="color" id="cta-color" value={ctaStyle.color} style={{width : "100%", height : "50%"}}
                                        onChange={(e)=>{setCtaStyle({...ctaStyle, color : e.target.value as string});}}/>
                                    </div>
                                    <div style={{width:"18%"}}>
                                        <InputLabel htmlFor="cta-bgColor">Background:</InputLabel>
                                        <input type="color" id="cta-bgColor" value={ctaStyle.backgroundColor} style={{width : "100%", height : "50%"}}
                                        onChange={(e)=>{setCtaStyle({...ctaStyle, backgroundColor : e.target.value as string});}}/>
                                    </div>
                                </div>
                             </div>
                            :null
                        }
                        
                    </div>
                    <div className="StepScreen">
                        <Container style={{
                            border:"1px solid grey", 
                            boxShadow : "0px 1px 2px 1px grey",  
                            display : "flex",
                            flexDirection: "column", 
                            borderRadius : "5px",
                        }}>
                            {
                                (textScreenStyle.textAlign === "left" || textScreenStyle.textAlign === "center" || textScreenStyle.textAlign === "right")
                                ?<div style={
                                    {   
                                        textAlign : textScreenStyle.textAlign, 
                                        backgroundColor : textScreenStyle.backgroundColor, 
                                        padding : `${textScreenStyle.padding}px`,
                                        fontSize : `${labelStyle.fontSize}px`
                                    }
                                 }>
                                    <h1 style={{fontSize : `${h1Style.fontSize}px`, color : h1Style.color}}>{(form.header.length > 0)? <span>{form.header}</span>:<span>This is an example form</span>}</h1>
                                    <p style={{fontSize : `${pStyle.fontSize}px`, color : pStyle.color}}>
                                        {(form.description.length > 0)? <span>{form.description}</span>:<span>This is an example description to encourage your users to fill the form</span>}
                                    </p>
                                    <label id="firstName-label" style={{color : labelStyle.color}}>
                                        { (form.firstNameLabel.length > 0) ? <span>{form.firstNameLabel}</span> : <span>First name</span> }
                                    </label><br/>
                                    <input id="fistName" placeholder={form.firstNameLabel} style={{
                                        width : `${inputStyle.width}%`,
                                        padding : `${inputStyle.padding}px`,
                                        fontSize : `${inputStyle.fontSize}px`,
                                        borderRadius : `${inputStyle.borderRadius}px`,
                                        border : "0.5px solid black"
                                    }}/>
                                    <br/><br/>
                                    <label id="firstName-label" style={{color : labelStyle.color}}>
                                        { (form.lastNameLabel.length > 0) ? <span>{form.lastNameLabel}</span> : <span>Last name</span> }
                                    </label><br/>
                                    <input id="fistName" placeholder={form.lastNameLabel} style={{
                                        width : `${inputStyle.width}%`,
                                        padding : `${inputStyle.padding}px`,
                                        fontSize : `${inputStyle.fontSize}px`,
                                        borderRadius : `${inputStyle.borderRadius}px`,
                                        border : "0.5px solid black"
                                    }}/>
                                    <br/><br/>
                                    <label id="firstName-label" style={{color : labelStyle.color}}>
                                        { (form.companyLabel.length > 0) ? <span>{form.companyLabel}</span> : <span>Company</span> }
                                    </label><br/>
                                    <input id="fistName" placeholder={form.companyLabel} style={{
                                        width : `${inputStyle.width}%`,
                                        padding : `${inputStyle.padding}px`,
                                        fontSize : `${inputStyle.fontSize}px`,
                                        borderRadius : `${inputStyle.borderRadius}px`,
                                        border : "0.5px solid black"
                                    }}/>
                                    <br/><br/>
                                    <label id="firstName-label" style={{color : labelStyle.color}}>
                                        { (form.emailLabel.length > 0) ? <span>{form.emailLabel}</span> : <span>Email</span> }
                                    </label><br/>
                                    <input id="fistName" placeholder={form.emailLabel} style={{
                                        width : `${inputStyle.width}%`,
                                        padding : `${inputStyle.padding}px`,
                                        fontSize : `${inputStyle.fontSize}px`,
                                        borderRadius : `${inputStyle.borderRadius}px`,
                                        border : "0.5px solid black"
                                    }}/>
                                    <br/><br/>
                                    <button style={{
                                    fontSize : `${ctaStyle.fontSize}px`, 
                                    padding : `${ctaStyle.padding}px`, 
                                    borderRadius : `${ctaStyle.borderRadius}px`, 
                                    backgroundColor : ctaStyle.backgroundColor, 
                                    color : ctaStyle.color,
                                    border : "none"
                                    }}>
                                        {(form.ctaText.length > 0)? <span>{form.ctaText}</span> : <span>Submit</span>}
                                    </button><br/><br/>
                                </div>
                                :<div style={{backgroundColor : textScreenStyle.backgroundColor, padding : `${textScreenStyle.padding}px`}}>
                                    <h1 style={{fontSize : `${h1Style.fontSize}px`, color : h1Style.color}}>{(form.header.length > 0)? <span>{form.header}</span>:<span>This is an example form</span>}</h1>
                                    <p style={{fontSize : `${pStyle.fontSize}px`, color : pStyle.color}}>
                                        {(form.description.length > 0)? <span>{form.description}</span>:<span>This is an example description to encourage your users to fill the form</span>}
                                    </p>
                                    <label id="firstName-label" style={{fontSize : labelStyle.fontSize, color : labelStyle.color}}>
                                        { (form.firstNameLabel.length > 0) ? <span>{form.firstNameLabel}</span> : <span>First name</span> }
                                    </label><br/>
                                    <input id="fistName" placeholder={form.firstNameLabel} style={{
                                        width : `${inputStyle.width}%`,
                                        padding : `${inputStyle.padding}px`,
                                        fontSize : `${inputStyle.fontSize}px`,
                                        borderRadius : `${inputStyle.borderRadius}px`,
                                        border : "0.5px solid black"
                                    }}/>
                                    <br/><br/>
                                    <label id="firstName-label" style={{fontSize : labelStyle.fontSize, color : labelStyle.color}}>
                                        { (form.lastNameLabel.length > 0) ? <span>{form.lastNameLabel}</span> : <span>Last name</span> }
                                    </label><br/>
                                    <input id="fistName" placeholder={form.lastNameLabel} style={{
                                        width : `${inputStyle.width}%`,
                                        padding : `${inputStyle.padding}px`,
                                        fontSize : `${inputStyle.fontSize}px`,
                                        borderRadius : `${inputStyle.borderRadius}px`,
                                        border : "0.5px solid black"
                                    }}/>
                                    <br/><br/>
                                    <label id="firstName-label" style={{fontSize : labelStyle.fontSize, color : labelStyle.color}}>
                                        { (form.companyLabel.length > 0) ? <span>{form.companyLabel}</span> : <span>Company</span> }
                                    </label><br/>
                                    <input id="fistName" placeholder={form.companyLabel} style={{
                                        width : `${inputStyle.width}%`,
                                        padding : `${inputStyle.padding}px`,
                                        fontSize : `${inputStyle.fontSize}px`,
                                        borderRadius : `${inputStyle.borderRadius}px`,
                                        border : "0.5px solid black"
                                    }}/>
                                    <br/><br/>
                                    <label id="firstName-label" style={{fontSize : labelStyle.fontSize, color : labelStyle.color}}>
                                        { (form.emailLabel.length > 0) ? <span>{form.emailLabel}</span> : <span>Email</span> }
                                    </label><br/>
                                    <input id="fistName" placeholder={form.emailLabel} style={{
                                        width : `${inputStyle.width}%`,
                                        padding : `${inputStyle.padding}px`,
                                        fontSize : `${inputStyle.fontSize}px`,
                                        borderRadius : `${inputStyle.borderRadius}px`,
                                        border : "0.5px solid black"
                                    }}/>
                                    <br/><br/>
                                    <button style={{
                                    fontSize : `${ctaStyle.fontSize}px`, 
                                    padding : `${ctaStyle.padding}px`, 
                                    borderRadius : `${ctaStyle.borderRadius}px`, 
                                    backgroundColor : ctaStyle.backgroundColor, 
                                    color : ctaStyle.color,
                                    border : "none"
                                    }}>
                                        {(form.ctaText.length > 0)? <span>{form.ctaText}</span> : <span>Submit</span>}
                                    </button><br/><br/>
                                </div>
                            }
                        </Container>
                    </div>  

                </div>
            </AccordionDetails>
        </Accordion>
    );
}

export default FormSelection;