import React, { useContext, useEffect, useState } from 'react';
import "./styles/App.css";
import "./styles/AddNewSequence.css";
import "./styles/previewPage.css";
import "./styles/sequences.css";
import "./styles/login.css";
import "./styles/home.css";
import { Button } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import AddNewSequence from './pages/addNewSequence';
import PreviewSequence from './pages/previewSequence';
import LeadMagnet from './pages/leadMagnet';
import { Context } from './components/Context';
import Login from './pages/login';
import EditSequence from './pages/editSequence';
import Home from './pages/home';
import LogoutIcon from '@mui/icons-material/Logout';
import LeadList from './pages/leadList';
import { useLocation } from 'react-router-dom';
import MainNavigation from './components/navigation';
import Topbar from './components/Topbar';
import Sequences from './pages/sequences';
import Register from './pages/register';
import Registration from './pages/registration';
import Settings from './pages/settings';
import Invitation from './pages/invite';


const App : React.FC = () : React.ReactElement=> {
  const { loggedIn, LogOut, pages, workspace } = useContext(Context);

  return (
    <div className="App">
      {
        (loggedIn === "ok")
        ?<>
          {
            (workspace !== undefined && workspace._id !== undefined && workspace._id.length > 0)
            ?<>
                <div className='navigation'>
                  <img src='/CX-Tailor-Logo-Light.png' alt='CX Tailor.com' style={{width : "70%", padding: "20px"}}/>
                  <MainNavigation pages={pages}/>
                </div>
                <div className='mainContainer'>
                  <Topbar/>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path='/sequences' element={<Sequences />} />
                    <Route path='/sequences/addNew' element={<AddNewSequence />} />
                    <Route path='/sequences/preview' element={<PreviewSequence />} />
                    <Route path='/sequences/leadMagnet' element={<LeadMagnet />} />
                    <Route path='/sequences/edit' element={<EditSequence />} />
                    <Route path='/leads' element={<LeadList />} />
                    <Route path='/settings' element={<Settings />} />
                  </Routes>
                </div>
             </>
          :<>
            <Routes>
              <Route path='/createWorkSpace' element={<Registration />} />
            </Routes>
          </>
          }
        </>
        :<>
          <div>
            <Routes>
              <Route path='/login' element={<Login />} />
              <Route path='/register' element={<Register />} />
              <Route path='/invite' element={<Invitation />} />
            </Routes>
          </div>
        </>
      }
    </div>
  );
}

export default App;
