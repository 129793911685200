import React, { useContext, useEffect, useState } from "react";
import { Context } from "../components/Context";
import { Alert, Button, Fade, TextField, TextareaAutosize, Typography } from "@mui/material";

const EmbedSequence : React.FC = () : React.ReactElement => {
    const {loadDone, selectedEmbed, selectedMagnet, setSelectedEmbed, apicall} = useContext(Context);
    const [copied, setCopied] = useState<boolean>(false);
    const [generateCode, setGenerateCode] = useState<boolean>(false);

    const generate = () => {
        const embedId : string = selectedMagnet._id;
            setSelectedEmbed(`
                <div id="LeadHarbor-${embedId}">
                    <div class="card-skeleton">
                        <div class="skeleton-image"></div>
                    </div>
                    
                    <style>.card-skeleton {
                        width: 100%;
                        height: calc(40vh);
                        background: #f0f0f0;
                        border-radius: 8px;
                        animation: shimmer 1.5s infinite;
                    }
                    
                    .skeleton-image {
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
                        background-size: 200% 100%;
                        animation: shimmer 1.5s infinite;
                    }
                    </style>
                    <script src="${apicall}/leadHarbor.js"></script>
                </div>`
            );
        setGenerateCode(true);
    }

    return(
        <div>
            {
                (loadDone && selectedEmbed)
                ?<div className="mainEmbed">
                    {
                        (generateCode)
                        ? <div className="EmbedCodeDiv">
                        <Typography variant="h5">Embed the following code to your website:</Typography>
                        <TextareaAutosize maxRows={15} disabled defaultValue={selectedEmbed} style={{width : "95%"}}/><br/>
                        <Button variant="contained" 
                        onClick={()=>{navigator.clipboard.writeText(selectedEmbed);
                            setCopied(true);
                            setTimeout(() => {
                                setCopied(false);
                            }, 5000);
                        }}
                        >Copy to clipboard</Button><br/>
                        <Fade in={copied}><Alert severity="success" style={{marginTop : "20px"}}>Script copied to clipboard</Alert></Fade>
                        </div>
                        : <div className="EmbedCodeDiv">
                            <Typography style={{margin : "10px"}} variant="h6">Click the button below to generate embeddable script:</Typography>
                            <Button onClick={()=>{generate()}} variant="contained" style={{margin : "10px"}}>Generate script</Button>
                          </div>
                    }
                 </div>
                : <Typography>Loading...</Typography>
            }
        </div>
    );
};

export default EmbedSequence;