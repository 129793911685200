import React, { useContext, useEffect, useState } from "react";
import { Context } from "../components/Context";
import { Alert, Backdrop, Button, CircularProgress, Collapse, FormControlLabel, Grid, TextField, Typography, Checkbox, InputLabel, InputAdornment, OutlinedInput, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export const FirstStep = (props : any) : React.ReactElement => {
    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
    const {userGetComplete, setUserGetComplete} = useContext(Context);
    const [showBackdrop, setShowBackdrop] = useState<boolean>(false);

    const startRegister = async(event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setUserGetComplete(undefined);
        setShowBackdrop(true);
        const connection = await fetch("/api/users/newWorkspace/checkUser/"+ props.username.toLowerCase());
        if(connection.status === 200){
            props.setEmailChecked(true);
        }
        else if(connection.status === 302){
            setUserGetComplete(false);
        }else{
            setUserGetComplete(false);
        }
    }

    return (
        <div style={{width :`${(window.innerWidth < 800)?"80%":"50%"}`}}>
            <div style={{marginTop:"20px", marginBottom:"20px"}}>
                <img src="CX-Tailor-Logo-Slogan.png" height={100} alt="CX Tailor logo"/>
            </div>
            <div style={{marginTop: "40px", marginBottom: "20px"}}>
                <Typography variant="h4">Signup for a free account </Typography><br/>
                <Typography>Forever free. No credit card required.</Typography>
            </div>
            <form onSubmit={startRegister}>
                <TextField id="email" fullWidth label="Business Email" variant="outlined"  type="email" className="loginField" margin="normal" required 
                onChange={(e)=>{props.setUsername(e.target.value); setUserGetComplete(undefined); setShowBackdrop(false)}}/><br/>
                <FormControlLabel label={<Typography>I agree to CX Tailor's Privacy Policy & Terms</Typography>} control={
                    <Checkbox checked={termsAccepted} onChange={()=>{setTermsAccepted(!termsAccepted)}}/>
                    }/>
                <Button className="btnMainClr" variant="contained" type="submit" startIcon={<ArrowForwardIcon />}
                disabled={(props.username.length < 2 || !termsAccepted)}
                sx={{width:"100%", backgroundColor: " #6156A5", padding: "10px", marginTop : "20px"}}
                >Create a free account</Button>
            </form>
            <Backdrop open={showBackdrop && userGetComplete === undefined}>
                <CircularProgress color="secondary"/>
            </Backdrop>
            <Collapse in={userGetComplete === false} sx={{marginTop: "20px"}}>
                <Alert severity="warning" variant="filled" onClose={()=>{setUserGetComplete(undefined); setShowBackdrop(false);}}>
                    An account with this email already exists.
                </Alert>
            </Collapse>
            <Typography>Already have an account? <Link to="/login">Sign in here.</Link></Typography>
        </div>
    );
}

export const LastStep = (props : any) : React.ReactElement => {
    const {userGetComplete, setUserGetComplete, startRegisteration, acceptInvite} = useContext(Context);
    const [showBackdrop, setShowBackdrop] = useState<boolean>(false);
    const [first_name, setFirst_name] = useState<string>("");
    const [last_name, setLast_name] = useState<string>("");
    const [company, setCompany] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [password2, setPassword2] = useState<string>("");
    const [showPassword1, setShowPassword1] = useState<boolean>(false);
    const [showPassword2, setShowPassword2] = useState<boolean>(false);
    const [errorMsg, SetErrorMsg] = useState<string>("");

    const register = async(event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setUserGetComplete(undefined);
        setShowBackdrop(true);
        if(password === password2){
            startRegisteration(props.username, password, first_name, last_name, company);
        }else{
            SetErrorMsg("Given passwords don't match...");
            setUserGetComplete(false);
        }

    }

    return(
        <div style={{width :`${(window.innerWidth < 800)?"80%":"50%"}`}}>
            <div style={{marginTop:"20px", marginBottom:"20px"}}>
                <img src="CX-Tailor-Logo-Slogan.png" height={100} alt="CX Tailor logo"/>
            </div>
            <div style={{marginTop: "40px", marginBottom: "20px"}}>
                    <Typography variant="h4">Finish signing up </Typography><br/>
                    <Typography>Fill in a few more details to continue to your free account.</Typography>
                </div>
            <form onSubmit={register}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sx={{padding: "5px 0px 5px 0px"}}>
                        <InputLabel htmlFor="email">Business Email:</InputLabel>
                        <TextField id="email" variant="outlined" value={props.username} fullWidth disabled type="email" className="loginField" required />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{padding: "5px 0px 5px 0px"}}>
                        <InputLabel htmlFor="company">Company</InputLabel>
                        <TextField id="company" variant="outlined" fullWidth type="text" className="loginField" required placeholder="Company" value={company}
                         onChange={(e)=>{setCompany(e.target.value as string)}}/>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{padding: "5px 0px 5px 0px"}}>
                        <InputLabel htmlFor="first_name">First name</InputLabel>
                        <TextField id="first_name" variant="outlined" fullWidth type="text" className="loginField" required placeholder="First name"
                         onChange={(e)=>{setFirst_name(e.target.value as string)}} />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{padding: "5px 0px 5px 0px"}}>
                        <InputLabel htmlFor="last_name">Last name</InputLabel>
                        <TextField id="last_name" variant="outlined" fullWidth type="text" className="loginField" required placeholder="Last name"
                         onChange={(e)=>{setLast_name(e.target.value as string)}}/>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{padding: "5px 0px 5px 0px"}}>
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <OutlinedInput id="password" fullWidth type={(showPassword1)?"text":"password"} className="loginField" required placeholder="Password" 
                        endAdornment={<InputAdornment position="end">
                            <IconButton onClick={()=>{setShowPassword1(!showPassword1)}}>
                                {(showPassword1)?<VisibilityOffIcon/> : <VisibilityIcon/>}
                            </IconButton>
                        </InputAdornment>} 
                         onChange={(e)=>{setPassword(e.target.value as string)}}/>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{padding: "5px 0px 5px 0px"}}>
                        <InputLabel htmlFor="password2">Repeat password</InputLabel>
                        <OutlinedInput id="password2" fullWidth type={(showPassword2)?"text":"password"} className="loginField" required placeholder="Password"
                        endAdornment={<InputAdornment position="end">
                            <IconButton onClick={()=>{setShowPassword2(!showPassword2)}}>
                                {(showPassword1)?<VisibilityOffIcon/> : <VisibilityIcon/>}
                            </IconButton>
                        </InputAdornment>} 
                         onChange={(e)=>{setPassword2(e.target.value as string)}}/>
                    </Grid>
                </Grid>
                <Button className="btnMainClr" variant="contained" type="submit" startIcon={<ArrowForwardIcon />}
                disabled={(props.username.length < 2 )}
                sx={{width:"100%", backgroundColor: " #6156A5", padding: "10px", marginTop : "20px", textTransform:"capitalize"}}
                >Continue to your free account</Button>
            </form>
            <Backdrop open={showBackdrop && userGetComplete === undefined}>
                <CircularProgress color="secondary"/>
            </Backdrop>
            <Collapse in={userGetComplete === false} sx={{marginTop: "20px"}}>
                <Alert severity="warning" variant="filled" onClose={()=>{setUserGetComplete(undefined); setShowBackdrop(false);}}>
                    {errorMsg}
                </Alert>
            </Collapse>
            <Typography>Already have an account? <Link to="/login">Sign in here.</Link></Typography>
        </div>
    );
}

const Register : React.FC = () : React.ReactElement => {
    const [username, setUsername] = useState<string>("");
    const [emailChecked, setEmailChecked] = useState<boolean>(false);

    return(
        <div className="LoginPage">
            <Grid container spacing={1} sx={{width: "100vw", overflow:"scroll", height: "100vh", scrollbarWidth:"none", margin:"-2px"}}>
                <Grid item xs={12} md={7} sx={{backgroundColor: "#F3F3F3", height: "100vh", display:"flex", alignItems:"center", justifyContent:"center"}}>
                    {
                        (emailChecked)
                        ?<LastStep username={username} />
                        :<FirstStep username={username} setUsername={setUsername} setEmailChecked={setEmailChecked}/>
                    }
                </Grid>
                <Grid item xs={12} md={5} sx={{display:"flex", justifyContent:"center", alignItems:"center", height: "100vh"}}>
                    <div style={{color:"white", textAlign: "center", width:"80%"}}>
                        <Typography variant="h4">Start optimising your website</Typography><br/>
                        <Typography>
                            Seamlessly capture leads with interactive video/questionnaire funnels. Elevate your
                            customer journey and maximize your conversions. Monitor and optimize the performance of your lead magnets through our user friendly dashboards
                        </Typography>
                        <img src="CX-Tailor-mockup.jpg" alt="CX Tailor mockup" height={window.innerHeight * 0.50}/>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default Register;