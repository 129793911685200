import React, {createContext, useEffect, useState} from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import ViewListIcon from '@mui/icons-material/ViewList';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';

export interface Lead {
    _id : string,
    form_id : string,
    workspace_id : string,
    firstName : string,
    lastName : string,
    company : string,
    email : string,
    quality : string,
    created_at : string,
    data : []
}

export interface VideoStyle {
    width : string,
    borderRadius : string,
}

export interface textScreenStyle {
    textAlign : string,
    padding : string,
    backgroundColor : string,
}

export interface TextStyle{
    fontSize : string,
    color : string,
}

export interface ctaStyle {
    fontSize : string,
    color : string,
    backgroundColor : string,
    padding : string,
    borderRadius : string
}

export interface InputStyle {
    width : string,
    padding : string,
    fontSize : string,
    borderRadius : string
}

export interface Video {
    url : string,
    poster : string,
    title : string,
    description : string,
    ctaText : string,
    showTimeLeft : boolean,
    timeLeftPersentage? : number,
    timeLeftText? : string,
    videoStyle? : VideoStyle,
    textScreenStyle? : textScreenStyle,
    h1Style? : TextStyle,
    pStyle? : TextStyle,
    ctaStyle? : ctaStyle, 
}

export interface ContactForm {
    header : string,
    description : string,
    firstNameLabel : string,
    lastNameLabel : string,
    companyLabel : string,
    emailLabel : string,
    emailResults : string,
    ctaText : string,
    textScreenStyle? : textScreenStyle,
    h1Style? : TextStyle,
    pStyle? : TextStyle,
    labelStyle? : TextStyle,
    inputStyle? : InputStyle,
    ctaStyle? : ctaStyle
}

export interface ThankYouPage {
    h1 : string,
    description : string,
    ctaText : string,
    ctaUrl : string,
    textScreenStyle? : textScreenStyle,
    h1Style? : TextStyle,
    pStyle? : TextStyle,
    ctaStyle? : TextStyle,
}

export interface Steps  {
    action : string,
    video? : Video,
    form? : ContactForm,
    thankYouPage? : ThankYouPage,
    views?: number,
    starts : number,
    finish: number
}

interface Props {
    children : React.ReactNode;
}

interface LeadMagnet {
    id? : string,
    workspace_id? : string,
    form_name? : string,
    created_at? : string,
    steps? : Steps[],
    data? : any
}

interface Workspace {
    _id : string,
    workspace_name : string,
    level : string,
    users? : [],
    sequences? : []
}

export interface User {
    _id : string,
    workspace_id : string,
    first_name : string,
    last_name : string,
    email : string,
    user_rights : string,
    onBoarding? : boolean,
    vertified? : boolean
}

interface formData {
    _id : string,
    form_id : string,
    workspace_id : string,
    step : number,
    action : string,
    date : string,
    source : string,
    medium : string,
    campaign : string
}

export const Context : React.Context<any> = createContext(undefined);

export const ContextProvider : React.FC<Props> = (props: Props) : React.ReactElement => {

    //Comment before local development:
    const apicall : string = "https://lead-harbor.onrender.com";

    //Comment before deployment:
    //const apicall : string = "http://localhost:3000";
    const location : any = useLocation();

    const navigate : NavigateFunction = useNavigate();
    const [currentPage, setCurrentPage] = useState<any>({page_title: "", page_description: ""});
    const [loadDone, setLoadDone] = useState<boolean>(false);
    const [loggedIn, setLoggedIn] = useState<string>("");
    const [inviteSent, setInviteSent] = useState<any>();

    //Variables that contain workspace info:
    const [workspace, setWorkspace] = useState<Workspace>();
    const [userGetComplete, setUserGetComplete] = useState<boolean>();
    const [user, setUser] = useState<User>();
    const [workspaceUsers, setWorkspaceUsers] = useState<User[]>();
    const [invitedUsers, setInvitedUsers] = useState<any>();

    //Variables that contain existing lead magnets:
    const [allMagnetDatas, setAllMagnetDatas] = useState<any>();
    const [listOfLeadMangets, setListOfLeadMangets] = useState<LeadMagnet[]>();
    const [listOfLeads, setListOfLeads] = useState<Lead[]>();
    const [selectedMagnet, setSelectedMagnet] = useState<LeadMagnet>();
    const [selectedMagnetLeads, setSelectedMagnetLeads] = useState<Lead[]>();
    const [selectedMagnetData, setSelectedMagnetData] = useState<formData[]>();
    const [selectedEmbed, setSelectedEmbed] = useState<string>();

    //Variables relating to creating a new lead magnet:
    const [newLeadMagnet, setNewLeadMagnet] = useState<LeadMagnet>();
    const [steps, setSteps] = useState<Steps[]>([]);
    const [accord, setAccord] = useState<number>(0);

    const [pages, setPages ] = useState<any>([
        {pageName : "Dashboard", url : "/", icon : <DashboardIcon />, description : ``},
        {pageName : "Sequences", url : "/sequences", icon : <ViewListIcon />},
        //{pageName : "Leads", url : "/leads", icon : <PeopleIcon />},
        {pageName : "Settings", url : "/settings", icon : <SettingsIcon />}
    ]);

    //function that runs after each page load:
    const loadFormStorage = async() => {
        const registrationProcess = sessionStorage.getItem("registration");
        /*if(registrationProcess){
            const parsedRegisteration = JSON.parse(registrationProcess);
            setUser(parsedRegisteration);
            setLoggedIn("ok");
            navigate("/createWorkSpace");
        }*/

        //selected lead magnet info:
        const sessionMagnet = sessionStorage.getItem("selectedMagnet");
        if(sessionMagnet){
            const parsedSelectedMagnet = JSON.parse(sessionMagnet);
            setSelectedMagnet(parsedSelectedMagnet);
            setCurrentPage({...currentPage, page_title : `Sequences | ${parsedSelectedMagnet.form_name}`});
            setSteps(parsedSelectedMagnet.steps);
            const connection = await fetch(`/api/forms/formData/${parsedSelectedMagnet._id}`);
            const data = await connection.json();
            setSelectedMagnetData(data);
        }
        //Active workspace info:
        const activeWorkspace = localStorage.getItem("workspace");
        if(activeWorkspace){
            setWorkspace(JSON.parse(activeWorkspace));
            const foundWorkspace = JSON.parse(activeWorkspace);
            const leadConnection = await fetch(`/api/leads/workspaceLeads/${foundWorkspace._id}`);
            const leadData = await leadConnection.json();
            setListOfLeads(leadData.reverse());

            const Dataconnection = await fetch(`/api/leads/workspaceData/${foundWorkspace._id}`);
            if(Dataconnection.status === 200){
                const data = await Dataconnection.json();
                data.sort((a: any , b : any) => b.conversion14Days - a.conversion14Days);
                setAllMagnetDatas(data);
            }

            const UserConnection = await fetch(`/api/users/workspaceUsers/${foundWorkspace._id}`);
            if(UserConnection.status === 200){
                const userData = await UserConnection.json();
                setWorkspaceUsers(userData);
            }

            const inviteConnection = await fetch(`/api/users/invitedUsers/${foundWorkspace._id}`);
            if(inviteConnection.status === 200){
                const invData = await inviteConnection.json();
                setInvitedUsers(invData);
            }
        }
        const leads = sessionStorage.getItem("selectedFormLeads");
        if(leads){
            setSelectedMagnetLeads(JSON.parse(leads));
        }
        const emmbedCode = sessionStorage.getItem("embedCode");
        if(emmbedCode){
            setSelectedEmbed(JSON.parse(emmbedCode));
        }

        const newMagnetDraft = sessionStorage.getItem("newLeadMagnet");
        if(newLeadMagnet){
            setNewLeadMagnet(JSON.parse(newMagnetDraft!));
        }
    }

    //This function is for user self registration process..
    const startRegisteration = async(email : string, password: string, first_name: string, last_name: string, company : string) => {
        try{
            const connection = await fetch("/api/users/newWorkSpace/firstUser/", 
                {
                method : "POST",
                headers : {'Accept' : "application/json" , "Content-Type" : "application/json"},
                body : JSON.stringify({
                    email : email,
                    password : password,
                    first_name : first_name,
                    last_name : last_name,
                    company : company
                })
                }
            );
            if(connection.status === 200){
                const userData = await connection.json();
                setUser(userData);
                setUserGetComplete(true);
                getWorkspace(userData.workspace_id);
                getAllLeads(userData.workspace_id);
                getAllData(userData.workspace_id);
                setLoggedIn("ok");
                localStorage.setItem("User", JSON.stringify((userData._id)));
                sessionStorage.setItem("registration", JSON.stringify(userData));
                navigate("/");
            }
            if(connection.status === 302){
                setUserGetComplete(false);
            }

        }catch(e:any){
            console.log(e);
        }
    }

    const inviteUser = async(email : string, user_rights : string, workspace_id : string, workspace_name : string, userEmail : string)=>{
        try{
            const connection = await fetch("/api/users/userInvite/", 
                {
                method : "POST",
                headers : {'Accept' : "application/json" , "Content-Type" : "application/json"},
                body : JSON.stringify({
                    email : email,
                    workspace_id : workspace_id,
                    user_rights : user_rights,
                    workspace_name : workspace_name,
                    userEmail : userEmail
                })
                }
            );

            if(connection.status === 200){
                setInviteSent({ message : "Invite sent succesfully", severity: "success"});
                const inviteData : any = await connection.json();
                setInvitedUsers(inviteData);
            }else if(connection.status === 302){
                setInviteSent({ message : "Given email already in use in another workspace", severity : "warning"});
            }else{
                setInviteSent({ message : "Something went wrong", severity : "error"});
            }

        }catch(e:any){
            console.log(e);
        }
    };

    const acceptInvite = async(email : string, password : string, first_name : string, last_name: string, workspace_id : string, inviteId : string, workspace_name : string)=>{
        try{
            const connection = await fetch(`/api/users/invitedUser/${workspace_id}`, 
                {
                method : "POST",
                headers : {'Accept' : "application/json" , "Content-Type" : "application/json"},
                body : JSON.stringify({
                    email : email,
                    password : password,
                    first_name : first_name,
                    last_name : last_name,
                    inviteId : inviteId
                })
                }
            );
            if(connection.status === 200){
                const userData = await connection.json();
                setUser(userData);
                setUserGetComplete(true);
                getWorkspace(userData.workspace_id);
                getAllLeads(userData.workspace_id);
                getAllData(userData.workspace_id);
                setLoggedIn("ok");
                localStorage.setItem("User", JSON.stringify((userData._id)));
                navigate("/");
            }
        }catch(e:any){

        }
    }

    const deleteUser = async(_id : string, workspace_id : string) => {
        try{
            const connection = await fetch(`/api/users/removeUser/${_id}/${workspace_id}`, {method : "DELETE"});
            if(connection.status === 200){
                getWorkspace(workspace_id);
                getAllLeads(workspace_id);
                getAllData(workspace_id);
                window.location.reload();
            }

        }catch(e:any){
            console.log(e);
        }
    }

    //function to handle logging in:
    const loginUser = async(email : string, password : string) => {
        try{
            const connection : any = await fetch(`/api/users/email/${email}`);
            const data : any = await connection.json();
            if(data.userFound){
                const userConnection : any = await fetch(`/api/users/get/${email}/${password}`);
                if(userConnection.status === 200){
                    const userData : any = await userConnection.json();
                    setUser(userData);
                    setUserGetComplete(true);
                    getWorkspace(userData.workspace_id);
                    getAllLeads(userData.workspace_id);
                    getAllData(userData.workspace_id);
                    setLoggedIn("ok");
                    localStorage.setItem("User", JSON.stringify((userData._id)));
                    if(userData.workspace_id === ""){
                        navigate("/createWorkspace")
                    }else{
                        navigate("/");
                    }
                }else{
                    setUserGetComplete(false);
                }
            }else{
                setUserGetComplete(false);
            }
        }catch(e:any){
            console.log(e);
        }
    }

    //function to get workspace info from server:
    const getWorkspace = async (id : string) => {
        try{
            const connection = await fetch(`/api/workspace/${id}`);
            const data = await connection.json();
            localStorage.setItem("workspace", JSON.stringify(data[0]));
            setWorkspace(data[0]);
            getAllUsers(data[0]._id);
            getAllInvites(data[0]._id);
            setNewLeadMagnet({...newLeadMagnet, workspace_id : data[0]._id});
            getAllSequences(data[0]._id);

        }catch(e:any){
            console.log(e);
        }
    }

    const getAllUsers = async(id : string) => {
        try{
            const UserConnection = await fetch(`/api/users/workspaceUsers/${id}`);
            if(UserConnection.status === 200){
                const userData = await UserConnection.json();
                setWorkspaceUsers(userData);
            }
        }catch(e:any){
            console.log(e);
        }
    }

    const getAllInvites = async(workspace_id : string)=>{
        try{
            const inviteConnection = await fetch(`/api/users/invitedUsers/${workspace_id}`);
            if(inviteConnection.status === 200){
                const inviteData = await inviteConnection.json();
                setInvitedUsers(inviteData);
            }
        }catch(e:any){
            console.log(e);
        }
    };

    //Function to get all sequences for workspace:
    const getAllSequences = async (id : string) => {
        try{
            const connection = await fetch(`/api/forms/workspace/${id}`);
            const data = await connection.json();
            setListOfLeadMangets(data);
        }catch(e:any){
            console.log(e);
        }
    }

    const getAllLeads = async (id : string) => {
        try{
            const connection = await fetch(`/api/leads/workspaceLeads/${id}`);
            const data = await connection.json();
            setListOfLeads(data.reverse());
        }catch(e:any){
            console.log(e);
        }
    }

    const getAllData = async (id : string) => {
        try{
            const connection = await fetch(`/api/leads/workspaceData/${id}`);
            const data = await connection.json();
            data.sort((a: any , b : any) => b.conversion - a.conversion);
            setAllMagnetDatas(data);
        }catch(e:any){
            console.log(e);
        }
    }

    //Function to get a sequence based on ID
    const getSpecificSequence = async (id : string) => {
        try{
            const connection = await fetch(`/api/forms/${id}`);
            const data = await connection.json();
            setSelectedMagnet(data[0]);
            sessionStorage.setItem("selectedMagnet", JSON.stringify(data[0]));
            const dataConnection = await fetch(`/api/forms/formData/${id}`);
            const formData = await dataConnection.json();
            setSelectedMagnetData(formData);

            const embedId : string = data[0]._id;
            
            setSelectedEmbed(`
                <div id="LeadHarbor-${embedId}">
                    <div class="card-skeleton">
                        <div class="skeleton-image"></div>
                    </div>
                    
                    <style>.card-skeleton {
                        width: 100%;
                        height: calc(40vh);
                        background: #f0f0f0;
                        border-radius: 8px;
                        animation: shimmer 1.5s infinite;
                    }
                    
                    .skeleton-image {
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
                        background-size: 200% 100%;
                        animation: shimmer 1.5s infinite;
                    }
                    </style>
                    <script src="${apicall}/leadHarbor.js"></script>
                </div>`
            );
            sessionStorage.setItem("embedCode", JSON.stringify(`
            <div id="LeadHarbor-${embedId}">
                <div class="card-skeleton">
                        <div class="skeleton-image"></div>
                    </div>
                    
                    <style>.card-skeleton {
                        width: 100%;
                        height: calc(40vh);
                        background: #f0f0f0;
                        border-radius: 8px;
                        animation: shimmer 1.5s infinite;
                    }
                    
                    .skeleton-image {
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
                        background-size: 200% 100%;
                        animation: shimmer 1.5s infinite;
                    }
                    </style>
                <script src="${apicall}/leadHarbor.js"></script>
            </div>`));
        }catch(e:any){
            console.log(e);
        }
    }

    const getFormLeads = async(form_id : string) => {
        try{
            const connection = await fetch(`/api/leads/getLeadInfo/${form_id}`);
            if(connection.status === 200){
                const data = await connection.json();
                setSelectedMagnetLeads(data);
                sessionStorage.setItem("selectedFormLeads", JSON.stringify(data));
            }
        }catch(e:any){
            console.log(e);
        }
    }

    //Function to delete a sequence based on ID:
    const deleteSequence = async(id: string, workspace_id : string)=> {
        try{
            const connection = await fetch(`/api/forms/delete/${id}`, {method : "DELETE"});
            if(connection.status === 200){
                getAllSequences(workspace_id);
                navigate("/sequences");
            }
        }catch(e:any){
            console.log(e);
        }
    }

    //navigate to a specific sequence preview:
    const openLeadMagnet = async (id: string) => {
        try{
            const connection = await fetch(`/api/forms/${id}`);
            const data = await connection.json();
            setSelectedMagnet(data[0]);
            sessionStorage.setItem("selectedMagnet", JSON.stringify(data[0]));
            const dataConnection = await fetch(`/api/forms/formData/${id}`);
            const formData = await dataConnection.json();
            setSelectedMagnetData(formData);
            getFormLeads(data[0]._id);
            setSteps(data[0].steps);
            if(data[0].steps !== null){
                setAccord((data[0].steps.length));
            }else{setAccord(0)}
            navigate("/sequences/leadMagnet");

        }catch(e:any){
            console.log(e);
        }
    }

    const openPreview = (id : string) => {
        getSpecificSequence(id);
        navigate("/sequences/preview");
    }

    //function to save new lead magnet steps:
    const SaveNewSteps = () => {
        setNewLeadMagnet({...newLeadMagnet, steps: steps});
        sessionStorage.setItem("newLeadMagnet", JSON.stringify(newLeadMagnet));
    }

    //Saves new lead magnet to database:
    const saveNewLeadMagnet = async () => {
        try{
            const connection = await fetch("/api/forms/newSequence", 
                {
                method : "POST",
                headers : {'Accept' : "application/json" , "Content-Type" : "application/json"},
                body : JSON.stringify(newLeadMagnet)
                }
            );
            if(connection.status === 200){
                sessionStorage.removeItem("embedCode");
                sessionStorage.removeItem("newSteps");
                sessionStorage.removeItem("newLeadMagnet");
                getAllSequences(workspace!._id);
                getAllData(workspace!._id);
                const data = await connection.json();
                getSpecificSequence(data.id);
                navigate("/sequences/preview");
            }

        }catch(e:any){
            console.log(e);
        }
    }

    const editLeadMagnet = async (id : string) => {
        try{
            const connection = await fetch(`/api/forms/editSteps/${id}`, 
            {method : "PUT",
            headers : {'Accept' : "application/json" , "Content-Type" : "application/json"},
            body : JSON.stringify({steps : steps})
           }
            );
            if(connection.status === 200){
                openLeadMagnet(id);
            }else{
                openLeadMagnet(id);
            }
        }catch(e:any){
            console.log(e);
        }
    };

    const editMagnetName = async(id : string) => {
        try{
            const connection = await fetch(`/api/forms/editName/${id}`,
                {
                    method : "PUT",
                    headers : {'Accept' : "application/json" , "Content-Type" : "application/json"},
                    body : JSON.stringify({form_name : selectedMagnet?.form_name})
                }    
            );
            if(connection.status === 200){
                openLeadMagnet(id);
                if(workspace?._id !== undefined)
                getAllSequences(workspace._id!);
            }else{
                const sessionMagnet = sessionStorage.getItem("selectedMagnet");
                if(sessionMagnet){
                    const parsedSelectedMagnet = JSON.parse(sessionMagnet);
                    setSelectedMagnet(parsedSelectedMagnet);
                    setSteps(parsedSelectedMagnet.steps);
                    const connection = await fetch(`/api/forms/formData/${parsedSelectedMagnet._id}`);
                    const data = await connection.json();
                    setSelectedMagnetData(data);
                }
            }

        }catch(e:any){
            console.log(e);
        }
    }

    const checkLoggedIn = async () =>{
        const localId = localStorage.getItem("User");
        if(localId){
            const user = JSON.parse(localId);
            const connection = await fetch(`/api/users/${user}`);
            if(connection.status === 200){
                const data = await connection.json();
                setUser(data);
                getWorkspace(data.workspace_id);
                if(data.workspace_id === ""){
                    navigate("/createWorkspace");
                }
                setLoggedIn("ok");
            }else{
                sessionStorage.clear();
                localStorage.clear();
                navigate("/login");
            }
        }else{
            if(location.pathname === "/register" || location.pathname === "/invite"){
                
            }else{
                navigate("/login");
            }
        }
    }

    const checkSessionSteps = async () => {
        const sessionSteps = sessionStorage.getItem("newSteps");

        if(sessionSteps){
            const parsedSteps = JSON.parse(sessionSteps);
            setSteps(parsedSteps);
            setAccord(parsedSteps.length);
        }
    }

    //Log out function:
    const LogOut = () =>{
        localStorage.clear();
        sessionStorage.clear();
        setLoggedIn("");
        navigate("/login");
    }

    //This function handles everything during each page load...
    useEffect(() => {
        checkLoggedIn();
        checkSessionSteps();
        loadFormStorage();
        pages.forEach((page: any)=>{
            if(location.pathname === page.url){
            setCurrentPage({...currentPage, page_title : page.pageName, page_description : page.description});
            }
        }); 
        setLoadDone(true);

    }, []);

    return(
        <Context.Provider value={
            {
                location,
                pages,
                currentPage,
                setCurrentPage,
                loadDone,
                setLoadDone,
                navigate,
                loggedIn,
                loginUser,
                deleteUser,
                startRegisteration,
                acceptInvite,
                workspaceUsers,
                user,
                userGetComplete,
                setUserGetComplete,
                inviteUser,
                invitedUsers,
                inviteSent,
                LogOut,
                workspace,
                apicall,
                //for existing leadmagnets:
                listOfLeadMangets,
                listOfLeads,
                allMagnetDatas,
                selectedMagnet,
                selectedMagnetLeads,
                selectedMagnetData,
                setSelectedMagnet,
                setSelectedEmbed,
                openLeadMagnet,
                openPreview,
                deleteSequence,
                selectedEmbed,
                editLeadMagnet,
                editMagnetName,
                //for new leadmagnets:
                newLeadMagnet,
                setNewLeadMagnet,
                steps,
                setSteps,
                SaveNewSteps,
                accord,
                setAccord,
                saveNewLeadMagnet,
            }
        } >
            {props.children}
        </Context.Provider>
    );
}

