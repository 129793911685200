import { Box, Button, Container, Grid, Modal, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { Context } from "../components/Context";
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import UpdateIcon from '@mui/icons-material/Update';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const Home : React.FC = () : React.ReactElement => {
    const {loadDone, user, navigate, newLeadMagnet, setSteps, setNewLeadMagnet, listOfLeadMangets, listOfLeads, allMagnetDatas, openLeadMagnet} = useContext(Context);
    const [popUp, setPopUp] = useState<boolean>(false);

    const handleAddNew = () => {
        if(newLeadMagnet.form_name.length > 0){
            sessionStorage.removeItem("newSteps");
            sessionStorage.setItem("newLeadMagnet", JSON.stringify(newLeadMagnet));
            sessionStorage.removeItem("selectedMagnet");
            setSteps([]);
            navigate("/sequences/addNew");
        }
    }

    return(
        <div className="mainFlexBox">
            {
                (loadDone && user)
                ?<>
                    <div className="homeTopBar">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8}>
                                <Typography variant="h6">Hello {`${user.first_name} ${user.last_name}`},</Typography>
                                <Typography>Welcome to CX Tailor</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} style={{textAlign: "right"}}>
                                <Button variant="contained" onClick={()=>{setPopUp(true)}}>Create a new lead magnet</Button>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="homeDasboard">
                        <div className="homeDashResults">
                            <div className="results-ColFull">
                                <Paper elevation={5}
                                style={{margin: "10px 30px 10px 30px", position:"relative", width: "15%", padding: "20px 10px 20px 10px"}}
                                >
                                    <Tooltip style={{position: "absolute", top:"10px", right: "10px", zIndex : 10}} title={
                                        <React.Fragment>
                                            <Typography>Lead magnets</Typography>
                                            {"Number of lead magnets in use"}
                                        </React.Fragment>
                                    }><HelpOutlineIcon /></Tooltip>
                                    
                                    <OndemandVideoIcon sx={{
                                            position: "absolute",
                                            top : "-20px",
                                            left : "-20px",
                                            padding : "20px",
                                            backgroundImage : "linear-gradient( 68.6deg,  rgba(30,150,170,1), rgba(0,86,158,1));",
                                            borderRadius : "10px",
                                            color:"white",
                                            fontSize: "40px",
                                            fontWeight : "600"
                                        }}/>
                                    <Typography variant="h3" style={{paddingRight : "50px", textAlign : "right", fontSize:"35px"}}>
                                        {
                                            (listOfLeadMangets)
                                            ?<span>{listOfLeadMangets.length}</span>
                                            :<span><Skeleton variant="rounded" animation="wave"/></span>
                                        }
                                    </Typography>
                                    <Typography style={{paddingTop : "20px", textAlign : "right"}}>
                                        Lead magnets in use
                                    </Typography>
                                </Paper>
                                <Paper elevation={5}
                                style={{margin: "10px 30px 10px 30px",position : "relative", padding: "20px 10px 20px 10px", width: "20%", }}
                                >
                                    <Tooltip style={{position: "absolute", top:"10px", right: "10px", zIndex : 10}} title={
                                        <React.Fragment>
                                            <Typography>Avg. conversion rate</Typography>
                                            {"Average conversion rate gives you an idea on how well your lead magnets are working."}<br/><br/>
                                            {"An ok conversion rate is +5%"}<br/><br/>
                                            {"Good conversion rate is +10%"}
                                        </React.Fragment>
                                    }><HelpOutlineIcon />
                                    </Tooltip>
                                    <FilterAltIcon sx={{
                                            position: "absolute",
                                            top : "-20px",
                                            left : "-20px",
                                            padding : "20px",
                                            backgroundImage : "linear-gradient( 68.6deg,  rgba(30,150,170,1), rgba(0,86,158,1));",
                                            borderRadius : "10px",
                                            color:"white",
                                            fontSize: "40px",
                                            fontWeight : "600"
                                        }}/>
                                    <Typography variant="h3" style={{paddingRight : "20px", textAlign : "right", fontSize:"35px"}}>
                                        {
                                            (allMagnetDatas && Object.keys(allMagnetDatas).length !== undefined && Object.keys(allMagnetDatas).length > 0)
                                            ?<>
                                                {
                                                    (allMagnetDatas.length > 0)
                                                    ?<span>{(allMagnetDatas.reduce((sum : any, currentValue : any) => sum + currentValue.conversion14Days, 0) / allMagnetDatas.length).toFixed(2)} %</span>
                                                    :<span><Skeleton variant="rounded" animation="wave"/></span>
                                                }
                                            </>
                                            :<span style={{fontSize: "18px"}}>Not enough data yet...</span>
                                        }
                                    </Typography>
                                    <Typography style={{paddingTop : "20px", textAlign : "right"}}>
                                        Avg. conversion rate
                                    </Typography>
                                </Paper>
                                <Paper elevation={5}
                                style={{margin: "10px 30px 10px 30px", position: "relative", padding: "20px 10px 20px 10px", width: "15%"}}
                                >
                                    <Tooltip style={{position: "absolute", top:"10px", right: "10px", zIndex : 10}} title={
                                        <React.Fragment>
                                            <Typography>Leads generated</Typography>
                                            {"How many new leads your lead magnets have generated overtime"}
                                        </React.Fragment>
                                    }><HelpOutlineIcon />
                                    </Tooltip>
                                    <PersonOutlineIcon sx={{
                                            position: "absolute",
                                            top : "-20px",
                                            left : "-20px",
                                            padding : "20px",
                                            backgroundImage : "linear-gradient( 68.6deg,  rgba(30,150,170,1), rgba(0,86,158,1));",
                                            borderRadius : "10px",
                                            color:"white",
                                            fontSize: "40px",
                                            fontWeight : "600"
                                        }}/>
                                    <Typography variant="h3" style={{paddingRight : "50px", textAlign : "right", fontSize:"35px"}}>
                                        {
                                            (listOfLeads)
                                            ?<span>{listOfLeads.length}</span>
                                            :<span><Skeleton variant="rounded" animation="wave"/></span>
                                        }
                                    </Typography>
                                    <Typography style={{paddingTop : "20px", textAlign : "right"}}>
                                        Leads generated
                                    </Typography>
                                </Paper>
                            </div>
                            <div className="results-Col1">
                                <div className="TopMagnet">
                                    <Typography variant="h6">Your best performing lead magnets:</Typography>
                                    <div style={{boxShadow : "0px 0px 2px 0px grey", borderRadius : "5px"}}>
                                    <TableContainer component={Paper} className="leadmagnetTable">
                                        <Table sx={{minHeight : 300, maxHeight : 300, overflow : "scroll"}} aria-label="top magnets">
                                            <TableHead>
                                                <TableCell><Typography variant="h6">Lead magnet name</Typography></TableCell>
                                                <TableCell><Typography variant="h6">Steps</Typography></TableCell>
                                                <TableCell><Typography variant="h6">Page views</Typography></TableCell>
                                                <TableCell><Typography variant="h6">Conversion %</Typography></TableCell>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    (allMagnetDatas)
                                                    ?<>
                                                        {(allMagnetDatas.lenght !== null && allMagnetDatas.length > 0)
                                                         ?<>
                                                            {
                                                                allMagnetDatas.slice(0,5).map((magnet : any, idx : number) =>{
                                                                    return <TableRow key={idx} hover onClick={()=>{openLeadMagnet(magnet.id)}}>
                                                                                <TableCell sx={{fontSize: "12px"}}>{magnet.name}</TableCell>
                                                                                <TableCell sx={{fontSize: "12px"}}>{Object.keys(magnet.steps14Days).length}</TableCell>
                                                                                <TableCell sx={{fontSize: "12px"}}>{magnet.steps14Days[0].view}</TableCell>
                                                                                <TableCell sx={{fontSize: "12px"}}>{magnet.conversion14Days}</TableCell>
                                                                        </TableRow>
                                                                })
                                                            }
                                                         </>
                                                         : <Typography sx={{padding: "20px"}}>This workspace has no active lead magnets....</Typography>
                                                        }                                                       
                                                    </>
                                                    :<>
                                                        {
                                                            [...Array(5)].map((e: any, idx : number)=>{
                                                                return <TableRow key={idx}>
                                                                            <TableCell><Skeleton variant="rounded" animation="wave"/></TableCell>
                                                                            <TableCell><Skeleton variant="rounded" animation="wave"/></TableCell>
                                                                            <TableCell><Skeleton variant="rounded" animation="wave"/></TableCell>
                                                                            <TableCell><Skeleton variant="rounded" animation="wave"/></TableCell>
                                                                      </TableRow>
                                                            })
                                                        }
                                                    </>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    </div>
                                </div>
                            </div>
                            <div className="results-Col2">
                                <div className="LeadList">
                                    <Typography variant="h6">Your newest leads:</Typography>
                                    <div style={{boxShadow : "0px 0px 2px 0px grey", borderRadius : "5px"}}>
                                    <TableContainer className="leadmagnetTable" component={Paper}>
                                        <Table sx={{minHeight : 300}}>
                                            <TableHead>
                                                <TableCell><Typography variant="h6">Lead email</Typography></TableCell>
                                                <TableCell><Typography variant="h6">Date</Typography></TableCell>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    (listOfLeads)
                                                    ?<>
                                                        {
                                                            (listOfLeads.length > 0)
                                                            ?<>
                                                                {listOfLeads.slice(0,5).map((lead : any, idx : number)=> {
                                                                    return <TableRow>
                                                                                <TableCell sx={{fontSize: "12px"}}>{lead.email}</TableCell>
                                                                                <TableCell sx={{fontSize: "12px"}}>{lead.created_at}</TableCell>
                                                                           </TableRow>
                                                                })}
                                                            </>
                                                            :<Typography sx={{padding: "20px"}}>No leads generated yet...</Typography>
                                                        }
                                                    </>
                                                    :<>
                                                    {
                                                        [...Array(5)].map((e: any, idx : number)=>{
                                                            return <TableRow key={idx}>
                                                                        <TableCell><Skeleton variant="rounded" animation="wave"/></TableCell>
                                                                        <TableCell><Skeleton variant="rounded" animation="wave"/></TableCell>
                                                                  </TableRow>
                                                        })
                                                    }
                                                </>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    </div>
                                </div>
                            </div>
                            <div className="suggestion-Col1">
                                <Paper elevation={5} style={{position : "relative", padding : "20px", width : "85%"}}>
                                    <ShowChartIcon sx={{
                                        position: "absolute",
                                        top : "-20px",
                                        left : "-20px",
                                        padding : "20px",
                                        backgroundImage : "linear-gradient( 68.6deg,  rgba(50,250,168,1), rgba(0,211,168,1));",
                                        borderRadius : "10px",
                                        color:"white",
                                        fontSize: "30px",
                                        fontWeight : "600"
                                    }}/>
                                    <Typography variant="h6" style={{paddingLeft : "50px"}}>Testing the funnel</Typography>
                                    <Typography style={{paddingTop: "20px"}}>
                                        Funnel testing manual & suggestions coming up soon...
                                    </Typography>
                                </Paper>
                            </div>
                            <div className="suggestion-Col2">
                                <Paper elevation={5} sx={{position: "relative", padding : "20px",  width : "85%"}}>
                                    <NotificationImportantIcon sx={{
                                            position: "absolute",
                                            top : "-20px",
                                            left : "-20px",
                                            padding : "20px",
                                            backgroundImage : "linear-gradient( 68.6deg,  rgba(220,150,150,1), rgba(209,83,121,1));",
                                            borderRadius : "10px",
                                            color:"white",
                                            fontSize: "30px",
                                            fontWeight : "600"
                                        }}/>
                                    <Typography variant="h6" style={{paddingLeft : "50px"}}>Optimization suggesitons</Typography>
                                    <Typography style={{paddingTop : "20px"}}>
                                        Optimization suggestions coming up soon...
                                    </Typography>
                                </Paper>
                            </div>
                            <div className="suggestion-Col3">
                                <Paper elevation={5} sx={{position: "relative", padding : "20px",  width : "85%"}}>
                                    <UpdateIcon sx={{
                                            position: "absolute",
                                            top : "-20px",
                                            left : "-20px",
                                            padding : "20px",
                                            backgroundImage : "linear-gradient( 68.6deg,  rgba(30,150,170,1), rgba(0,86,158,1));",
                                            borderRadius : "10px",
                                            color:"white",
                                            fontSize: "30px",
                                            fontWeight : "600"
                                        }}/>
                                    <Typography variant="h6" style={{paddingLeft : "50px"}}>Recent updates</Typography>
                                    <Typography style={{paddingTop : "20px"}}>
                                        Update log coming up soon...
                                    </Typography>
                                </Paper>
                            </div>
                        </div>
                    </div>
                </>
                : <Typography>Loading....</Typography>
            }
            <Modal open={popUp} onClose={()=>{setPopUp(false)}}>
                    <Box style={{
                        position : "absolute", 
                        top :"50%", left :"50%", 
                        backgroundColor : "white", 
                        padding : "20px", 
                        borderRadius : "5px",
                        transform : "translate(-50%, -50%)",
                        textAlign : "center",
                        }}>
                        <Container>
                            <Typography variant="h4">Create a new lead magnet</Typography>
                            <br/>
                            <Typography>Add internal name for your lead magnet:</Typography>
                            <TextField name="Lead Magnet name" placeholder="Name your lead magnet..."
                            style={{margin : "20px"}} 
                            onChange={(e)=>{setNewLeadMagnet({...newLeadMagnet, form_name : e.target.value as string})}}/><br/>
                            <Button onClick={()=>{setPopUp(false);}} style={{margin : "5px"}} variant="outlined" color="secondary">Cancel</Button>
                            <Button variant="contained" onClick={()=>{handleAddNew()}}>Continue</Button>
                        </Container>
                    </Box>
            </Modal>
        </div>
    );
};

export default Home;