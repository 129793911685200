import React, { useContext, useEffect, useState } from "react";
import { Context, ThankYouPage, textScreenStyle, TextStyle, ctaStyle } from "./Context";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, ButtonGroup, Container, InputAdornment, InputLabel, OutlinedInput, Tab, Tabs, TextField, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SegmentIcon from '@mui/icons-material/Segment';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';

const NewEndSelection = (props : any) : React.ReactElement => {
    const {steps, setSteps, SaveNewSteps} = useContext(Context);
    const [expand, setExpand] = useState<boolean>(true);
    const [tabValue, setTabvalue] = useState<number>(0);
    const [thx, setThx] = useState<ThankYouPage>({
        h1 : "",
        description : "",
        ctaText : "",
        ctaUrl : ""
    });
    const [textScreenStyle, setTextScreenStyle] = useState<textScreenStyle>({textAlign : "left", padding : "", backgroundColor : ""});
    const [h1Style, setH1style] = useState<TextStyle>({fontSize : "", color : ""});
    const [pStyle, setPStyle] = useState<TextStyle>({fontSize : "", color : ""});
    const [ctaStyle, setCtaStyle] = useState<ctaStyle>({fontSize : "", color: "", backgroundColor : "", padding : "", borderRadius : ""});

    const updateValues = () => {
        if(steps[props.idx].thankYouPage){
            setThx(steps[props.idx].thankYouPage);
            if(steps[props.idx].thankYouPage.textScreenStyle){
                setTextScreenStyle(steps[props.idx].thankYouPage.textScreenStyle);
            }
            if(steps[props.idx].thankYouPage.h1Style){
                setH1style(steps[props.idx].thankYouPage.h1Style);
            }
            if(steps[props.idx].thankYouPage.pStyle){
                setPStyle(steps[props.idx].thankYouPage.pStyle);
            }
            if(steps[props.idx].thankYouPage.ctaStyle){
                setCtaStyle(steps[props.idx].thankYouPage.ctaStyle);
            }

        }
    }

    const removeStep = () => {
        sessionStorage.setItem("newSteps", JSON.stringify(steps.filter((step : any) => step !== steps[props.idx])));
        setSteps(steps.filter((step : any) => step !== steps[props.idx]));
        props.setAccord(props.accord - 1);
        props.setEnd(false);
    }

    const handleStyleChange = () => {
        setThx({...thx, textScreenStyle : textScreenStyle, h1Style : h1Style, pStyle : pStyle, ctaStyle : ctaStyle});
    }

    const saveStep = () => {
        handleStyleChange();
        steps[props.idx].thankYouPage = thx;
        SaveNewSteps();
        setExpand(false);
        sessionStorage.setItem("newSteps", JSON.stringify(steps));
    }

    useEffect(()=>{
        updateValues();
    }, []);

    return(
        <Accordion key={props.idx} expanded={expand} style={{boxShadow: "0px 0px 3px 1px grey"}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={()=>{setExpand(!expand)}}>
                <Typography sx={{width : "15%", flexShrink : 0}}>Step {props.idx + 1}: {steps[props.idx].action}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div className="StepSelectionFlexBox">
                    <div className="StepSelection">
                        <Box sx={{borderBottom : 1, borderColor : "divider", marginBottom : "20px"}}>
                            <Tabs value={ tabValue }>
                                <Tab icon={<SegmentIcon />} iconPosition="start" label="Content" onClick={()=>{setTabvalue(0); handleStyleChange();}} />
                                <Tab icon={<InvertColorsIcon />} iconPosition="start" label="Style" onClick={()=>{setTabvalue(1);}} />
                            </Tabs>
                        </Box>
                        {
                            (tabValue === 0)
                            ?<div>
                                <Typography variant="h6">Build your thank you page:</Typography>
                                    <br/>
                                    <InputLabel id="h1-label">Header</InputLabel>
                                    <TextField fullWidth id="h1" variant="outlined" size="small" placeholder="Write your header here..." 
                                    value={thx.h1}
                                    onChange={(e)=>{setThx({...thx, h1 : e.target.value as string})}} 
                                    />
                                    <br/><br/>
                                    <InputLabel id="description-label">Engaging description</InputLabel>
                                    <TextField fullWidth id="description" variant="outlined" size="small" placeholder="Write your description here..." multiline rows={3}
                                    value={thx.description}
                                    onChange={(e)=>{setThx({...thx, description : e.target.value as string})}} 
                                    />
                                    <br/>
                                    <div className="StepDetailsFlex">
                                        <div style={{flex : "40%"}}>
                                            <InputLabel id="ctaText-label">Call to aciton - Text</InputLabel>
                                            <TextField id="ctaText" placeholder="Call to action..." size="small" variant="outlined" fullWidth
                                            value={thx.ctaText}
                                            onChange={(e)=>{setThx({...thx, ctaText : e.target.value as string})}}
                                            />
                                        </div>
                                        <div style={{flex : "40%"}}>
                                            <InputLabel id="ctaUrl-label">Call to action - URL</InputLabel>
                                            <TextField id="ctaUrl" placeholder="Insert URL here..." size="small" variant="outlined" fullWidth
                                            value={thx.ctaUrl}
                                            onChange={(e)=>{setThx({...thx, ctaUrl : e.target.value as string})}}
                                            />
                                        </div>
                                    </div>
                                    <br/>
                                    <Button variant="outlined" color="secondary" onClick={saveStep} style={{margin : "0px 10px 0px 0px"}}><b>Save thank you page</b></Button> 
                                    <Button variant="contained" style={{margin : "0px 0px 0px 10px"}} color="error" onClick={removeStep}>Remove this step</Button>
                             </div>
                            : null
                        }
                        {
                            (tabValue === 1)
                            ?<div>
                                <div className="StepDetailsFlex" style={{marginBottom : "20px"}}>
                                <div style={{width:"30%"}}>
                                        <InputLabel htmlFor="video-width">Text align:</InputLabel>
                                        <ButtonGroup size="large">
                                            {
                                                (textScreenStyle.textAlign === "left")
                                                ?<Button onClick={()=>{setTextScreenStyle({...textScreenStyle, textAlign : "left"})}} variant="contained">
                                                    <FormatAlignLeftIcon />
                                                </Button>
                                                :<Button onClick={()=>{setTextScreenStyle({...textScreenStyle, textAlign : "left"})}}>
                                                    <FormatAlignLeftIcon />
                                                </Button>
                                            }
                                            {
                                                (textScreenStyle.textAlign === "center")
                                                ?<Button onClick={()=>{setTextScreenStyle({...textScreenStyle, textAlign : "center"})}} variant="contained">
                                                    <FormatAlignCenterIcon />
                                                </Button>
                                                :<Button onClick={()=>{setTextScreenStyle({...textScreenStyle, textAlign : "center"})}}>
                                                    <FormatAlignCenterIcon />
                                                </Button>
                                            }
                                            {
                                                (textScreenStyle.textAlign === "right")
                                                ?<Button onClick={()=>{setTextScreenStyle({...textScreenStyle, textAlign : "right"})}} variant="contained">
                                                    <FormatAlignRightIcon />
                                                </Button>
                                                :<Button onClick={()=>{setTextScreenStyle({...textScreenStyle, textAlign : "right"})}}>
                                                    <FormatAlignRightIcon />
                                                </Button>
                                            }
                                        </ButtonGroup>
                                    </div>
                                    <div style={{width:"30%"}}>
                                        <InputLabel htmlFor="padding">Text screen padding: </InputLabel>
                                        <OutlinedInput id="padding" endAdornment={<InputAdornment position="end">px</InputAdornment>} type="number"
                                        value={textScreenStyle.padding} 
                                        onChange={(e)=>{setTextScreenStyle({...textScreenStyle, padding : e.target.value as string})}} placeholder="20" />
                                    </div>
                                    <div style={{width:"30%"}}>
                                        <InputLabel htmlFor="bg-color">Background:</InputLabel>
                                        <input type="color" id="bg-color" value={textScreenStyle.backgroundColor} style={{width : "50%", height : "50%"}}
                                        onChange={(e)=>{setTextScreenStyle({...textScreenStyle, backgroundColor : e.target.value as string})}}/>
                                    </div>
                                </div>
                                <div className="seperator">Text styling</div>
                                <div className="StepDetailsFlex" style={{marginBottom : "20px"}}>
                                    <div style={{width:"30%"}}>
                                        <InputLabel htmlFor="h1Size">Title font size:</InputLabel>
                                        <OutlinedInput id="h1Size" endAdornment={<InputAdornment position="end">px</InputAdornment>} placeholder="30" type="number"
                                        value={h1Style.fontSize} onChange={(e)=>{setH1style({...h1Style, fontSize : e.target.value as string});}}
                                        />
                                    </div>
                                    <div style={{width:"30%"}}>
                                        <InputLabel htmlFor="pSize">p -font size:</InputLabel>
                                        <OutlinedInput id="pSize" endAdornment={<InputAdornment position="end">px</InputAdornment>} placeholder="18" type="number"
                                        value={pStyle.fontSize} onChange={(e)=>{setPStyle({...pStyle, fontSize : e.target.value as string});}}
                                        />
                                    </div>
                                    <div style={{width:"15%"}}>
                                        <InputLabel htmlFor="title-color">Title color:</InputLabel>
                                        <input type="color" id="title-color" value={h1Style.color} style={{width : "100%", height : "50%"}}
                                        onChange={(e)=>{setH1style({...h1Style, color : e.target.value as string});}}/>
                                    </div>
                                    <div style={{width:"15%"}}>
                                        <InputLabel htmlFor="p-color">p -color:</InputLabel>
                                        <input type="color" id="p-color" value={pStyle.fontSize} style={{width : "100%", height : "50%"}}
                                        onChange={(e)=>{setPStyle({...pStyle, color : e.target.value as string});}}/>
                                    </div>
                                </div>
                                <div className="seperator">CTA styling</div>
                                <div className="StepDetailsFlex" style={{marginBottom : "20px"}}>
                                    <div style={{width:"18%"}}>
                                        <InputLabel htmlFor="cta-Size">Font size:</InputLabel>
                                        <OutlinedInput id="cta-Size" endAdornment={<InputAdornment position="end">px</InputAdornment>} placeholder="16" type="number"
                                        value={ctaStyle.fontSize} onChange={(e)=>{setCtaStyle({...ctaStyle, fontSize : e.target.value as string});}}
                                        />
                                    </div>
                                    <div style={{width:"18%"}}>
                                        <InputLabel htmlFor="cta-padding">Padding:</InputLabel>
                                        <OutlinedInput id="cta-padding" endAdornment={<InputAdornment position="end">px</InputAdornment>} placeholder="10" type="number"
                                        value={ctaStyle.padding} onChange={(e)=>{setCtaStyle({...ctaStyle, padding : e.target.value as string});}}
                                        />
                                    </div>
                                    <div style={{width:"18%"}}>
                                        <InputLabel htmlFor="cta-Size">Border radius:</InputLabel>
                                        <OutlinedInput id="cta-Size" endAdornment={<InputAdornment position="end">px</InputAdornment>} placeholder="10" type="number"
                                        value={ctaStyle.borderRadius} onChange={(e)=>{setCtaStyle({...ctaStyle, borderRadius : e.target.value as string});}}
                                        />
                                    </div>
                                    <div style={{width:"15%"}}>
                                        <InputLabel htmlFor="cta-color">Font color:</InputLabel>
                                        <input type="color" id="cta-color" value={ctaStyle.color} style={{width : "100%", height : "50%"}}
                                        onChange={(e)=>{setCtaStyle({...ctaStyle, color : e.target.value as string});}}/>
                                    </div>
                                    <div style={{width:"18%"}}>
                                        <InputLabel htmlFor="cta-bgColor">Background:</InputLabel>
                                        <input type="color" id="cta-bgColor" value={ctaStyle.backgroundColor} style={{width : "100%", height : "50%"}}
                                        onChange={(e)=>{setCtaStyle({...ctaStyle, backgroundColor : e.target.value as string});}}/>
                                    </div>
                                </div>
                             </div>
                            :null
                        }
                    </div>
                    <div className="StepScreen">
                        <Container style={{
                            border:"1px solid grey", 
                            boxShadow : "0px 1px 2px 1px grey", 
                            padding: "20px",  
                            display : "flex",
                            flexDirection: "column", 
                            borderRadius : "5px"
                        }}>
                            {
                                (textScreenStyle.textAlign === "left" || textScreenStyle.textAlign === "center" || textScreenStyle.textAlign === "right")
                                ?<div style={{textAlign : textScreenStyle.textAlign, padding : `${textScreenStyle.padding}px`, backgroundColor : textScreenStyle.backgroundColor}}>
                                    <br/>
                                    <h2 style={{fontSize : `${h1Style.fontSize}px`, color : h1Style.color}}>
                                        {(thx.h1.length > 0)?<span>{thx.h1}</span>:<span>This is an example title...</span>}
                                    </h2>
                                    <p style={{fontSize : `${pStyle.fontSize}px`, color : pStyle.color}}>
                                        {(thx.description.length > 0)?<span>{thx.description}</span>:<span>This is an example description that will encourage you to click through...</span>}
                                    </p>
                                    <button style={{
                                        fontSize : `${ctaStyle.fontSize}px`,
                                        color : ctaStyle.color,
                                        backgroundColor : ctaStyle.backgroundColor,
                                        padding : `${ctaStyle.padding}px`,
                                        borderRadius : `${ctaStyle.borderRadius}px`,
                                        border : "none"
                                    }}>
                                        {(thx.ctaText.length > 0)?<span>{thx.ctaText}</span>:<span>Example final CTA</span>}
                                    </button>
                                </div>
                                :<div>

                                </div>
                            }
                        </Container>
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>
    );
}

export default NewEndSelection;