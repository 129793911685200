import React, { useContext, useEffect, useState } from "react";
import "../styles/AddNewSequence.css";
import { Box, Button, Container, Modal, Typography } from "@mui/material";
import MovieIcon from '@mui/icons-material/Movie';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Context} from "../components/Context";
import SaveIcon from '@mui/icons-material/Save';
import VideoSelection from "../components/VideoSelection";
import FormSelection from "../components/FormSelection";
import EndSelection from "../components/EndSelection";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


const EditSequence : React.FC = () : React.ReactElement => {
    const {steps, setSteps, loadDone, accord, setAccord, editLeadMagnet, selectedMagnet, openLeadMagnet} = useContext(Context);
    const [formUsed, setFormUsed] = useState<boolean>(false);
    const [end, setEnd] = useState<boolean>(false);
    const [deletePop, setDeletePop] = useState<boolean>(false);

    const updateValues = () => {
        if(steps !== null && steps.length !== null){
            setAccord(steps.length)
            for(let i = 0; i < steps.length ; i++){
                if(steps[i].action === "end screen"){
                    setEnd(true);
                }
                if(steps[i].action === "form"){
                    setFormUsed(true);
                }
            }
        }
    }

    useEffect(()=>{
        updateValues();
    }, []);

    return(
        <div className="mainFlexBox">
            <div className="flexTextMain">
                <Button startIcon={<ArrowBackIcon />} color="secondary" onClick={()=>{openLeadMagnet(selectedMagnet._id)}}>Cancel and return</Button>
                <Typography variant="h5">Edit your new video sequence: {`(${selectedMagnet.form_name})`}</Typography><br/>
            </div>
            <div className="flexItemLeft">
                <div className="subFlexBox">
                    <div className="subFlexLeft">
                        <Typography variant="h6">Add steps:</Typography><br/>
                    </div>
                    <div className="subFlexRight">
                        {
                            (steps !== null)
                            ?<>
                                {
                                    (steps.length > 0)
                                    ? <Button variant="contained" onClick={()=>{editLeadMagnet(selectedMagnet._id)}} startIcon={<SaveIcon />}>Save changes & Exit</Button>
                                    : <Button variant="contained" disabled={true}>Save changes & Exit</Button>
                                }
                             </>
                            :<Button variant="contained" disabled={true}>Save changes & Exit</Button>
                        }
                    </div>
                </div>
                {
                    (loadDone)
                    ?<>{
                        (steps !== null && steps.length !== null)
                        ? [...Array(accord)].map((e, idx) => {
                            return  <>{
                                (steps[idx].action === "video")
                                ?<VideoSelection idx={idx} accord={accord} setAccord={setAccord} />
                                :(steps[idx].action === "form")
                                    ?<FormSelection idx={idx} accord={accord} setAccord={setAccord} setFormUsed={setFormUsed}/>
                                    :(steps[idx].action === "end screen")
                                        ?<EndSelection idx={idx} accord={accord} setAccord={setAccord} setEnd={setEnd} />
                                        :null
                            }</>;
                        })
                        : null
                    }</>
                    :<Typography>Loading...</Typography>
                }
                <br/>
                <Button style={{margin: "10px"}} variant="contained" disabled={end} 
                startIcon={<MovieIcon />} 
                onClick={()=>{setSteps([...steps, {action: "video", video : {
                    url: "",
                    poster : "", 
                    title : "", 
                    description: "", 
                    ctaText : "",
                    showTimeLeft : false,
                }, views : 0, starts: 0, finish: 0}]); setAccord(accord + 1);}}
                >Add a video step</Button> 
                <Button style={{margin: "10px"}} variant="contained" disabled={end || formUsed}  
                startIcon={<QuestionAnswerIcon />} 
                onClick={()=>{setFormUsed(true); setAccord(accord + 1); setSteps([...steps, {action: "form", form : {
                    header : "",
                    description: "",
                    firstNameLabel : "",
                    lastNameLabel : "",
                    companyLabel : "",
                    emailLabel : "",
                    emailResults : "",
                    ctaText : ""
                }, starts : 0, finish: 0}])}}
                >Add contact form</Button>
                <Button style={{margin: "10px"}} variant="contained" disabled={end} 
                startIcon={<ArtTrackIcon />} 
                onClick={()=>{setEnd(true); setAccord(accord + 1); setSteps([...steps, {action: "end screen", thankYouPage : {
                    h1 : "",
                    description : "",
                    ctaText : "",
                    ctaLink : ""
                }, starts : 0, finish : 0}])}}
                >Add end screen</Button>
                <Button style={{margin: "10px"}} variant="contained" color="error" 
                startIcon={<ArtTrackIcon />} 
                onClick={()=>{setDeletePop(true);}}
                >Remove all steps</Button>
            </div>
            <Modal open={deletePop} onClose={()=>{setDeletePop(false)}}>
                    <Box style={{
                        position : "absolute", 
                        top :"50%", left :"50%", 
                        backgroundColor : "white", 
                        padding : "20px", 
                        borderRadius : "5px",
                        transform : "translate(-50%, -50%)",
                        textAlign : "center",
                        }}>
                        <Container>
                             <Typography variant="h6" style={{margin: "20px 20px 20px 20px"}}>
                                You are about to delete all steps from your existing lead magnet
                             </Typography>
                             <br/>
                             <Typography>Are you sure you want to continue?</Typography>
                             <Button variant="outlined" startIcon={<ArrowBackIcon />} color="error" 
                             onClick={()=>{setDeletePop(false)}} style={{margin: "20px"}}>No, return</Button>
                             <Button variant="contained" style={{margin: "20px"}} startIcon={<DeleteForeverIcon />} color="success"
                             onClick={()=>{setAccord(0); setSteps([])}}>Yes, delete</Button>                
                        </Container>
                    </Box>
            </Modal>
        </div>
    );
}

export default EditSequence;