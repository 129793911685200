import { Box, Step, StepLabel, Stepper, Typography } from "@mui/material";
import React, { useState } from "react";
import "../styles/registerWorkspace.css";

export const BusinessName = () : React.ReactElement =>{
    return(
        <div className="questionBox">
            <Typography>Who are you?</Typography>
        </div>
    );
}

export const Source = () : React.ReactElement => {
    return(
        <div className="questionBox">
            <Typography>Where did you hear about CX Tailor?</Typography>
        </div>
    );
}

const Registration : React.FC = () : React.ReactElement => {
    const [currentStep, setCurrentStep] = useState<number>(0);
    const steps = ["Step 1", "Step 2", "Step 3", "Step 4"];
    const questions = [<BusinessName />, <Source />]

    return(
        <div className="registerationMain">
            <Box sx={{width : "60%"}}>
                <Stepper activeStep={currentStep} sx={{width:"100%"}}>  
                    {steps.map((step: any, idx: number) => {
                        return<Step key={idx}>
                                <StepLabel>{step}</StepLabel>
                            </Step>
                    })}
                </Stepper>
                {questions[currentStep]}
            </Box>
        </div>
    );
};

export default Registration;